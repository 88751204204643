import axios, { AxiosInstance } from 'axios';
import { baseLocalURL } from 'config';
const baseURL = baseLocalURL + '/api/';
const apiService: AxiosInstance = axios.create({
  baseURL
});
// Function to set token in headers
export const setAuthToken = (token: string | null): void => {
  if (token) {
    apiService.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete apiService.defaults.headers.common['Authorization'];
  }
};
// Add any default headers or configurations here
export const getMethode = async (
  url: string,
  params: Record<string, any> = {}
): Promise<any> => {
  try {
    const response = await apiService.get(url, { params });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
// export const postMethode = async (
//   url: string,
//   data: Record<string, any> = {}
// ): Promise<any> => {
//   try {
//     const response = await apiService.post(url, data);
//     return response.data;
//   } catch (error: any) {
//     throw error.response.data;
//   }
// };
export const postMethode = async (
  url: string,
  formData: FormData
): Promise<any> => {
  try {
    const response = await apiService.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
export const delMethode = async (url: string): Promise<any> => {
  try {
    const response = await apiService.delete(url);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
// Add other HTTP methods (put, patch, etc.) as needed
export default apiService;
