/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type EditAction = () => void;
type RemoveAction = () => void;
type InvoiceListingAction = () => void;

interface ActionDropdownItemsProps {
  editAction?: EditAction; // Make the prop optional
  removeAction?: RemoveAction;
  invoiceListingAction?: InvoiceListingAction;
}

const InvoiceListDropdownItems: React.FC<ActionDropdownItemsProps> = ({
  editAction = () => {},
  removeAction = () => {},
  invoiceListingAction = () => {}
}) => {
  return (
    <>
      <Dropdown.Item eventKey="1" onClick={editAction}>
        Edit
      </Dropdown.Item>
      {/* <Dropdown.Item eventKey="2">Export</Dropdown.Item> */}
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-info"
        onClick={invoiceListingAction}
      >
        Invoice Listing
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-danger"
        onClick={removeAction}
      >
        Remove
      </Dropdown.Item>
    </>
  );
};

export default InvoiceListDropdownItems;
