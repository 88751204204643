import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import CardViewItem from 'components/modules/project-management/card-view/CardViewItem';
import CompletedSiteCardItem from 'components/modules/project-management/card-view/CompletedSiteCardItem';
import SiteCardViewItem from 'components/modules/project-management/card-view/SiteCardViewItem';
import { Site, projects } from 'data/project-management/projects';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getService } from 'services/services';

export const columns: ColumnDef<Site>[] = [
  {
    // For filtering and searching projects by status
    id: 'site_id',
    accessorFn: ({ site_id }) => site_id
  },
  {
    // For searching projects by name
    accessorKey: 'name'
  }
];

const CompletedSiteCardView = () => {
  const [siteList, setSiteList] = useState([]);

  console.log('siteList', siteList);

  const fetch = async () => {
    const response = await getService('site_completed');
    if (response.status === 200) {
      setSiteList(response.data);
      // return <Redirect to="/" />;
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  const table = useAdvanceTable<Site>({
    data: siteList,
    columns,
    pageSize: 1000,
    pagination: true,
    sortable: true
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Completed Site</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({siteList.length})
            </span>
          </h2>
          {/* <Link className="btn btn-primary px-5" to="/site/create-new">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Create New Site
          </Link> */}
        </div>
        <ProjectsTopSection activeView="card" />
        <Row className="g-3 mb-9">
          {table
            .getRowModel()
            .rows.map(row => row.original)
            .map(site => (
              <Col xs={12} sm={6} xl={4} xxl={3} key={site.id}>
                {/* <SiteCardViewItem project={site} /> */}
                <CompletedSiteCardItem project={site} />
              </Col>
            ))}
        </Row>
      </AdvanceTableProvider>
    </div>
  );
};

export default CompletedSiteCardView;
