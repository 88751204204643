import Button from 'components/base/Button';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'components/base/DatePicker';
import { FormEvent, useEffect, useState } from 'react';
import { addService } from 'services/services';
import { toast } from 'react-toastify';
import { formatDateForAPI, validationErrorFromAPI } from 'helpers/utils';

interface Field {
  site_id: any;
  amount: number;
  percent: string;
  retention_duration: string;
  retention_count: string;
}
interface FieldsRes {
  date: any;
  amount: any;
}

interface Props {
  budget: any;
}

// eslint-disable-next-line react/prop-types
const AddSiteRetention: React.FC<Props> = ({ budget }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [percentageAmt, setPercentageAmt] = useState<number>(0);
  const [retentionAmt, setRetentionAmt] = useState<number>(0);
  const [resMonth, setResMonth] = useState<any>();
  const [resPercentage, setResPercentage] = useState<any>();

  const [fields, setFields] = useState<Field>({
    site_id: id,
    amount: budget,
    percent: '',
    retention_count: '',
    retention_duration: ''
  });

  const [dataList, setDataList] = useState<Array<FieldsRes>>([]);

  useEffect(() => {
    const count = parseInt(fields.retention_count);
    const newDataList = isNaN(count)
      ? []
      : Array.from({ length: count }, () => ({
          date: '',
          amount: retentionAmt
        }));
    setDataList(newDataList);
  }, [retentionAmt, fields.retention_count]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFields(prevFields => ({
      ...prevFields,
      [name]: value
    }));

    if (name === 'percent') {
      const enteredPercentage: number = parseFloat(value);
      setResPercentage(enteredPercentage);
      if (!isNaN(enteredPercentage)) {
        const calculatedResult: number = (budget * enteredPercentage) / 100;
        if (resMonth) {
          const calculatedAmount: number = calculatedResult / resMonth;
          setRetentionAmt(calculatedAmount);
        }
        setPercentageAmt(calculatedResult);
      }
    }
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
    if (name === 'retention_count') {
      const enteredMonth: number = parseFloat(value);
      setResMonth(enteredMonth);
      console.log('enteredMonth', enteredMonth);
      if (!isNaN(enteredMonth)) {
        const calculatedAmount: number = percentageAmt / enteredMonth;
        setRetentionAmt(calculatedAmount);
        console.log('calculatedAmount', calculatedAmount);
      }
    }
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();

    const combinedData = {
      ...fields,
      site_retention_detail: JSON.stringify(dataList)
    };
    const response = await addService('retention', combinedData);
    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      navigate('/siteRetentionListView');
    } else if (response?.status === 500) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };
  return (
    <div>
      <h4 className="mb-4">Add Retention detail</h4>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={6} md={2}>
              <FloatingLabel controlId="floatingInputGrid" label="Site Budget">
                <Form.Control
                  type="number"
                  placeholder="Budget"
                  name="Budget"
                  // value={fields['name']}
                  // onChange={handleChange}
                  value={budget}
                  disabled={true}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={3}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Enter Retention Percentage %"
              >
                <Form.Control
                  type="number"
                  placeholder="percentage"
                  name="percent"
                  value={fields['percent']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={3}>
              <FloatingLabel
                controlId="floatingSelectPrivacy"
                label="Retention Year"
              >
                <Form.Select
                  name="retention_duration"
                  value={fields['retention_duration']}
                  onChange={handleSelect}
                >
                  <option>Select Retention</option>
                  <option value="1">1 Year</option>
                  <option value="2">2 Year</option>
                  <option value="3">3 Year</option>
                  <option value="4">4 Year</option>
                  <option value="5">5 Year</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={3}>
              <FloatingLabel
                controlId="floatingSelectPrivacy"
                label="Retention duration"
              >
                <Form.Select
                  name="retention_count"
                  value={fields['retention_count']}
                  onChange={handleSelect}
                >
                  <option value="0">Select Retention</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </Form.Select>
              </FloatingLabel>
            </Col>

            <Col sm={12} md={12}>
              {dataList.map((data, index) => (
                <Row key={index} className="mb-1">
                  <Col sm={6} md={4}>
                    <DatePicker
                      value={data.date}
                      options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                      onChange={([date]) => {
                        const newDataList = [...dataList];
                        newDataList[index].date = formatDateForAPI(date);
                        setDataList(newDataList);
                      }}
                      render={(_, ref) => {
                        return (
                          <Form.Floating>
                            <Form.Control
                              placeholder="Date"
                              ref={ref}
                              id={`date_${index}`}
                            />
                            <label htmlFor={`date_${index}`} className="ps-6">
                              Select Retention Date
                            </label>
                          </Form.Floating>
                        );
                      }}
                    />
                  </Col>
                  <Col sm={6} md={3}>
                    <FloatingLabel controlId={`amount_${index}`} label="Amount">
                      <Form.Control
                        type="number"
                        placeholder="percentage"
                        name="amount"
                        value={retentionAmt}
                        readOnly
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              ))}
            </Col>

            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to="/workOrder">
                  <Button variant="phoenix-primary" className="px-5">
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSave}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AddSiteRetention;
