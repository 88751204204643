import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import { projects } from 'data/project-management/projects';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Button from 'components/base/Button';
import UnitListTable from 'components/tables/UnitListTable';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { ColumnDef } from '@tanstack/react-table';
import { jsonToFormData, validationErrorFromAPI } from 'helpers/utils';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { toast } from 'react-toastify';

interface Project {
  id: number;
  name: string;
  created_at: string;
  unit_title: string;
}
type EditAction = () => void;

const UnitListView = () => {
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState('');
  const [unitList, setUnitList] = useState([]);
  const [unitName, setUnitName] = useState('');
  const [unitId, setUnitId] = useState('');

  const handleShow = (data: any) => {
    setShow(true);
    setUnitId(data.id);
    setUnitName(data.unit_title);
  };
  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setUnitId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`unit/${unitId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setUnitId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const unitListTableColumns: ColumnDef<Project>[] = [
    {
      header: 'Id',
      accessorKey: 'id',
      meta: {
        cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
        headerProps: { style: { width: '10%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'unit_title',
      header: 'Unit Name',
      cell: ({ row: { original } }) => {
        const { unit_title } = original;
        return (
          <Link to="#!" className="text-decoration-none fw-bold fs-8">
            {unit_title}
          </Link>
        );
      },
      meta: {
        cellProps: { className: 'white-space-nowrap py-4' },
        headerProps: { style: { width: '30%' } }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems
                editAction={() => handleShow(original)}
                removeAction={() => confirmAlert(id)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: unitList,
    columns: unitListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  const handleClose = () => {
    setShow(false);
    setUnitId('');
    setUnitName('');
    setError('');
  };

  const handleSave = async () => {
    if (unitName === '') {
      setError('Please enter an unit title.');
      return;
    } else {
      const data = jsonToFormData({ name: unitName });
      let response: any = {};
      if (unitId) {
        response = await addService(`unit/${unitId}`, data);
      } else {
        response = await addService('unit', data);
      }
      if (response?.status === 200) {
        toast.success('SuccessFully Added', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        fetch();
        handleClose();
        // return <Redirect to="/" />;
      } else if (response?.status === 500) {
        toast.error('Error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        validationErrorFromAPI(response.data);
      }
    }
    // Add your save logic here
  };

  const fetch = async () => {
    const response = await getService('unit');
    if (response.status === 200) {
      setUnitList(response.data);
      // return <Redirect to="/" />;
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Industry'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Unit</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({unitList.length})
            </span>
          </h2>
          <Button
            onClick={() => setShow(true)}
            variant="primary"
            className="px-5"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Add Unit
          </Button>
        </div>
        <ProjectsTopSection activeView="list" />
        <UnitListTable />
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{unitId ? 'Edit' : 'Create'} Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Add Unit</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter unit"
                  autoFocus
                  value={unitName}
                  onChange={e => {
                    setUnitName(e.target.value);
                    setError('');
                  }}
                  isInvalid={error !== ''}
                />
                <Form.Control.Feedback type="invalid">
                  {error}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </AdvanceTableProvider>
    </div>
  );
};

export default UnitListView;
