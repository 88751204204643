import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { jsonToFormData, validationErrorFromAPI } from 'helpers/utils';
import { FloatingLabel } from 'react-bootstrap';
import { toast } from 'react-toastify';
import StageDropdownItems from 'components/common/StageDropdownItems';

interface ComRateList {
  id: number;
  company_name: any;
  industry_name: any;
  count: number;
}
interface ComRateList {
  id: number;
  company_name: any;
  industry_name: any;
  count: number;
}

interface IndustryStage {
  id: number;
  company_name: string;
  industry_name: string;
  count: number;
}

interface Company {
  id: number;
  name: string;
}

const CompanyMaterial = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [companyRateId, setCompanyRateId] = useState('');
  const [materialName, setMaterialName] = useState('');
  const [comRateList, setComRateList] = useState<IndustryStage[]>([]);
  const [fields, setFields] = useState({
    company_id: '',
    material_id: '',
    quantity: '',
    per_q_rate: ''
  });

  const [role, setRole] = useState(0);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleShow = (data: any) => {
    console.log('handleShow', data);
    setMaterialName(data.material.name);
    setCompanyRateId(data.id);
    setShow(true);
    setFields({
      company_id: data.company_id,
      material_id: data.material_id,
      quantity: data.quantity,
      per_q_rate: data.per_q_rate
    });
  };

  const handleClose = () => {
    setShow(false);
    // setCompanyId('');
    // setCompanyName('');
    // setIndustryName('');
    // setError('');
  };

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setCompanyRateId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`companyRateList/${companyRateId}`);
    if (response?.status === 200) {
      fetch();
      setShowAlert(false);
      setCompanyRateId('');
    }
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    const data = jsonToFormData(fields);
    let response: any = {};
    response = await addService(`companyRateList/${companyRateId}`, data); // Remove const keyword

    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      handleClose();
      fetch();
    } else {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };

  const fetch = async () => {
    const response = await getService('companyRateList');
    if (response.status === 200) {
      const processApiResponse = (data: any[]): IndustryStage[] => {
        const companyMap = new Map<string, IndustryStage>(); // Use Map for efficient lookups

        for (const item of data) {
          const companyName = item.company.name;
          const industryName = item.company.industry.name;
          const stageName = item.stage_name;

          if (!companyMap.has(industryName)) {
            companyMap.set(industryName, {
              id: 0,
              company_name: companyName,
              industry_name: industryName,
              count: 0
            });
          }

          const industryStage = companyMap.get(industryName)!;
          industryStage.count++; // Increment count for each stage in the industry
        }

        return Array.from(companyMap.values()); // Convert Map to array
      };

      // Assuming you have the API data in a variable called 'apiData'
      const processedData = processApiResponse(response.data);
      //   setStageList(processedData);
      console.log('IIIII', processedData);
      setComRateList(processedData);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<ComRateList>[] = [
    {
      accessorKey: 'company_name',
      header: 'Company Name',
      meta: {
        headerProps: { style: { width: '200px' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'industry_name',
      header: 'Industry Name',
      meta: {
        headerProps: { style: { width: '200px' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'count',
      header: 'No of Material',
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <StageDropdownItems
                  detailsAction={() => navigate('/companyRateList')}
                  //   removeAction={() => confirmAlert(id)}
                />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: comRateList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Company Rate'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5 text-uppercase">Company Material</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {role === 1 && (
                  <Link
                    className="btn btn-primary px-5"
                    to="/createCompanyRateList"
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add Rates
                  </Link>
                )}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Edit Company Rate List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Material Name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Material Name"
                          name="material_id"
                          value={materialName}
                          disabled
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={3}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Quantity"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Quantity"
                          name="quantity"
                          value={fields['quantity']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={3}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="PER QTY RATE"
                      >
                        <Form.Control
                          type="number"
                          placeholder="PER QTY RATE"
                          name="per_q_rate"
                          value={fields['per_q_rate']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              {/* <Button variant="primary" > */}
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default CompanyMaterial;
