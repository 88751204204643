import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addService, getService } from 'services/services';
import {
  dateFormat,
  formatDateForAPI,
  openInNewTab,
  validationErrorFromAPI
} from 'helpers/utils';
import { toast } from 'react-toastify';
import { ProgressBar } from 'react-bootstrap';
import { FloatingLabel } from 'react-bootstrap';
import DatePicker from 'components/base/DatePicker';

interface Stage {
  status: number;
}
interface Contractor {
  id: number;
  site_name: string;
  site_id: string;
  contractor: {
    first_name: string;
    last_name: string;
  };
  start_date: string;
  actual_end_date: string;
  stagem: Stage[]; // Array of Stage objects
}

interface Field {
  site_ids: any;
  invoice_no: string;
  invoice_date: string | Date;
  vendor_code: string;
  nature_of_supply: string;
  pan_no: string;
  supply_p_from: string | Date;
  supply_p_to: string | Date;
  place: string;
}

const WorkOrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [contractorList, setContractorList] = useState([]);
  const [siteSelection, setSiteSelection] = useState<number[]>([]);
  const [existingInvoiceNumbers, setExistingInvoiceNumbers] = useState<
    string[]
  >([]);
  const [fields, setFields] = useState<Field>({
    site_ids: '',
    invoice_no: '',
    invoice_date: '',
    vendor_code: '',
    nature_of_supply: '',
    pan_no: '',
    supply_p_from: '',
    supply_p_to: '',
    place: ''
  });

  const initialFieldsState = {
    site_ids: '',
    invoice_no: '',
    invoice_date: '',
    vendor_code: '',
    nature_of_supply: '',
    pan_no: '',
    supply_p_from: '',
    supply_p_to: '',
    place: ''
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
    if (name === 'invoice_no') {
      validateInvoiceNumber(value);
    }
  };

  const validateInvoiceNumber = (value: string) => {
    if (existingInvoiceNumbers && existingInvoiceNumbers.includes(value)) {
      toast.error('Duplicate Invoice Number. Please enter a different one.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setShow(false);
      setFields({ ...initialFieldsState });
    } else {
      console.log('done');
    }
  };

  const handleShow = () => {
    if (siteSelection.length > 0) {
      setShow(true);
    }
  };

  const handleClose = () => {
    setShow(false);
    setFields({ ...initialFieldsState });
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    fields.invoice_date = formatDateForAPI(fields.invoice_date);
    fields.supply_p_to = formatDateForAPI(fields.supply_p_to);
    fields.supply_p_from = formatDateForAPI(fields.supply_p_from);
    fields.site_ids = siteSelection.length > 0 ? siteSelection.join(',') : '';
    let response: any = {};
    response = await addService('workorder_invoice', fields);
    if (response?.status === 200) {
      setSiteSelection([]);
      setFields({ ...initialFieldsState });
      setShow(false);
      fetchData();
      await openInNewTab(response.data.url);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Contractor>[] = [
    {
      accessorKey: 'site_name',
      header: 'Site Name',
      meta: {
        headerProps: {
          // style: { width: '15%', minWidth: '200px' },
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'site_id',
      header: 'Site ID',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'contractor',
      header: 'Contractor',
      cell: ({ row: { original } }) => {
        const { first_name, last_name } = original.contractor;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {first_name} {last_name}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'actual_start_date',
      header: 'Actual Start Date',
      cell: ({ row: { original } }) => {
        const { start_date } = original;
        return (
          <span className="text-body-secondary fs-9 mb-0">
            <p>{dateFormat(start_date)}</p>
          </span>
        );
      },
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'actual_end_date',
      header: 'Actual End Date',
      cell: ({ row: { original } }) => {
        const { actual_end_date } = original;
        return (
          <span className="text-body-secondary fs-9 mb-0">
            {actual_end_date ? <p>{dateFormat(actual_end_date)}</p> : ''}
          </span>
        );
      },
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'stagem',
      header: 'Progress Status',
      cell: ({ row: { original } }) => {
        const { stagem } = original;
        const countStatus5 = stagem.filter(
          (item: any) => item.status === 5
        ).length;
        return (
          <>
            <p className="text-body-secondary fs-10 mb-0">
              {countStatus5} / {stagem.length}
            </p>
            <ProgressBar
              now={(countStatus5 / stagem.length) * 100}
              style={{ height: 3 }}
              variant="success"
            />
          </>
        );
      },
      meta: {
        headerProps: {
          style: { width: '10%', minWidth: '50px' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-end text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'invoice_no',
      header: 'Invoice No.',
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    }
  ];

  const table = useAdvanceTable({
    data: contractorList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    enableRowSelection: (row: any) => {
      const progressStatus = row.original.stagem.filter(
        (item: any) => item.status === 5
      ).length;
      return (
        row.original.invoice_no == null &&
        row.original.stagem.length === progressStatus
      );
    }
  });

  useEffect(() => {
    if (table.getGroupedSelectedRowModel().rows.length > 0) {
      const selectedIDs: number[] = [];
      table.getGroupedSelectedRowModel().rows.map(row => {
        selectedIDs.push(row.original.id);
      });
      setSiteSelection(selectedIDs);
    }
  }, [table.getGroupedSelectedRowModel()]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await getService(`workorder/${id}`);
    if (response.status === 200) {
      setContractorList(response.data[0].site);
      const invoiceNumbers = response.data[0].site.map(
        (item: any) => item.invoice_no
      );
      setExistingInvoiceNumbers(invoiceNumbers);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  return (
    <div>
      <div className="mb-9">
        <h3 className="mb-5">Work Order Details</h3>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                <Button
                  onClick={handleShow}
                  variant="primary"
                  className="px-5"
                  disabled={siteSelection.length === 0}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Create Invoice
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Create Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-2">
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Invoice No."
                      >
                        <Form.Control
                          type="text"
                          placeholder="Enter Invoice no."
                          name="invoice_no"
                          value={fields['invoice_no']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <DatePicker
                        value={fields['invoice_date']}
                        options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                        onChange={([date]) => {
                          setFields({
                            ...fields,
                            invoice_date: formatDateForAPI(date)
                          });
                        }}
                        render={(_, ref) => {
                          return (
                            <Form.Floating>
                              <Form.Control
                                placeholder="Project title"
                                ref={ref}
                                id="invoice_date"
                              />
                              <label htmlFor="startDate" className="ps-6">
                                Invoice date
                              </label>
                            </Form.Floating>
                          );
                        }}
                      />
                    </Col>

                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Vendor Code"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Enter Invoice no."
                          name="vendor_code"
                          value={fields['vendor_code']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={8}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="NATURE OF SUPPLY"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Enter Invoice no."
                          name="nature_of_supply"
                          value={fields['nature_of_supply']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>

                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="PAN No."
                      >
                        <Form.Control
                          type="text"
                          placeholder="Enter Invoice no."
                          name="pan_no"
                          value={fields['pan_no']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <DatePicker
                        value={fields['supply_p_from']}
                        options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                        onChange={([date]) => {
                          setFields({
                            ...fields,
                            supply_p_from: formatDateForAPI(date)
                          });
                        }}
                        render={(_, ref) => {
                          return (
                            <Form.Floating>
                              <Form.Control
                                placeholder="Supply"
                                ref={ref}
                                id="supply_p_from"
                              />
                              <label htmlFor="startDate" className="ps-6">
                                SUPPLY PERIOD FROM{' '}
                              </label>
                            </Form.Floating>
                          );
                        }}
                      />
                    </Col>
                    <Col sm={6} md={6}>
                      <DatePicker
                        value={fields['supply_p_to']}
                        options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                        onChange={([date]) => {
                          setFields({
                            ...fields,
                            supply_p_to: formatDateForAPI(date)
                          });
                        }}
                        render={(_, ref) => {
                          return (
                            <Form.Floating>
                              <Form.Control
                                placeholder="Supply"
                                ref={ref}
                                id="supply_p_to"
                              />
                              <label htmlFor="startDate" className="ps-6">
                                SUPPLY PERIOD TO
                              </label>
                            </Form.Floating>
                          );
                        }}
                      />
                    </Col>
                    <Col xs={12}>
                      <FloatingLabel
                        controlId="floatingProjectOverview"
                        label="PLACE OF SUPPLY OF SERVICE"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Leave a comment here"
                          style={{ height: '75px' }}
                          name="place"
                          value={fields['place']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default WorkOrderDetails;
