import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { getService } from 'services/services';
import { dateFormat, formatDate, formatDateForAPI } from 'helpers/utils';
import DatePicker from 'components/base/DatePicker';
import JsonToCsvButton from 'components/JsonToCsvButton';

interface Project {
  id: number;
  damage_qty: number;
  remark: string;
  damage_date: string;
  masterinventory: {
    maintan_date: string;
    batch_no: number;
    barcode: number;
    invoice_no: number;
    material: {
      name: string;
    };
  };
}

interface DateTo {
  toDate: any;
  fromDate: any;
}

interface Field {
  site_id: number;
  expense_id: string;
  amount: string;
  paid_amount: string;
  comments: string;
  mode_of_payment: string;
  expenses_date: any;
  status: any;
  bill_doc: any;
}

const DamageList = () => {
  const [damageList, setDamageList] = useState([]);
  const [balanceAmt, setBalanceAmt] = useState<number>(0);
  const [paidAmt, setPaidAmt] = useState<number>(0);
  const [gstAmt, setGstAmt] = useState<number>(0);
  const [invoiceAmt, setInvoiceAmt] = useState<number>(0);

  const filteredData = damageList.map((data: any) => ({
    material_name: data.masterinventory.material.name,
    damage_qty: data.damage_qty,
    remark: data.remark,
    damage_date: data.damage_date,
    updated_at: data.updated_at,
    invoice_no: data.masterinventory.invoice_no,
    barcode: data.masterinventory.barcode,
    batch_no: data.masterinventory.batch_no,
    expiry_date: data.masterinventory.maintan_date
  }));

  const [fields, setFields] = useState<DateTo>({
    toDate: '',
    fromDate: ''
  });

  const toDate = formatDateForAPI(fields.toDate);
  const fromDate = formatDateForAPI(fields.fromDate);

  const grandTotal = (data: any) => {
    if (data != 'avark') {
      // Initialize totals
      const totals = data.reduce(
        (
          accumulator: {
            invoiceAmount: number;
            gstAmount: number;
            paidAmount: number;
            balanceAmount: number;
          },
          item: any
        ) => {
          return {
            invoiceAmount:
              accumulator.invoiceAmount + parseFloat(item.invoice_amt || '0'),
            gstAmount: accumulator.gstAmount + parseFloat(item.gst_amt || '0'),
            paidAmount:
              accumulator.paidAmount + parseFloat(item.paid_amt || '0'),
            balanceAmount:
              accumulator.balanceAmount + parseFloat(item.balance_amt || '0')
          };
        },
        { invoiceAmount: 0, gstAmount: 0, paidAmount: 0, balanceAmount: 0 }
      );

      setInvoiceAmt(totals.invoiceAmount.toFixed(2));
      setGstAmt(totals.gstAmount.toFixed(2));
      setPaidAmt(totals.paidAmount.toFixed(2));
      setBalanceAmt(totals.balanceAmount.toFixed(2));
    } else {
      setInvoiceAmt(0.0);
      setGstAmt(0.0);
      setPaidAmt(0.0);
      setBalanceAmt(0.0);
    }
  };

  // const handleGst = async () => {
  //   const response = await getService(
  //     `supplier_invoice_gst_list/${toDate}/${fromDate}`
  //   );
  //   if (response.status === 200) {
  //     setGstList(response.data);
  //     grandTotal(response.data);
  //   } else {
  //     setGstList([]);
  //     grandTotal('avark');
  //   }
  // };

  const clearToDate = (event: any) => {
    event.preventDefault();
    setFields({
      ...fields,
      toDate: ''
    });
    fetch();
  };
  const clearFromDate = (event: any) => {
    event.preventDefault();
    setFields({
      ...fields,
      fromDate: ''
    });
    fetch();
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      const response = await getService('master_inventory_damage_list');
      if (response.status === 200) {
        setDamageList(response.data);
        grandTotal(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Project>[] = [
    {
      accessorKey: 'masterinventory.material.name',
      header: 'Material Name',
      cell: ({ row: { original } }) => {
        const { name } = original.masterinventory.material;
        return (
          // <Link to="#!" className="d-flex align-items-center text-body">
          <Link to="#!" className="">
            {/* <p className="mb-0 ms-3 text-body-emphasis fw-semibold"> */}
            <p className="mb-0 fw-bold">{name}</p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '10%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'Damage date',
      header: 'Damage date',
      cell: ({ row: { original } }) => {
        const { damage_date } = original;
        return (
          <span className="text-body-secondary fs-9 mb-0">
            {damage_date ? dateFormat(damage_date) : ''}
          </span>
        );
      },
      meta: {
        headerProps: { style: { width: '20%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'damage_qty',
      header: 'Damage Quantity',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '20%' } }
      }
    },
    {
      accessorKey: 'remark',
      header: 'Remark',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '20%' } }
      }
    },
    {
      accessorKey: 'masterinventory.invoice_no',
      header: 'Invoice no',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '20%' } }
      }
    },
    {
      accessorKey: 'masterinventory.barcode',
      header: 'Barcode no',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '20%' } }
      }
    },
    {
      accessorKey: 'masterinventory.batch_no',
      header: 'Batch no',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '20%' } }
      }
    },
    {
      accessorKey: 'Damage date',
      header: 'Damage date',
      cell: ({ row: { original } }) => {
        const { maintan_date } = original.masterinventory;
        return (
          <span className="text-body-secondary fs-9 mb-0">
            {maintan_date ? dateFormat(maintan_date) : ''}
          </span>
        );
      },
      meta: {
        headerProps: { style: { width: '20%' } },
        cellProps: { className: 'text-body' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: damageList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <div>
      <div className="mb-9">
        <h4 className="mb-5">Damage Report Table</h4>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search...."
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs="auto">
                <DatePicker
                  value={fields['toDate']}
                  options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                  onChange={([date]) => {
                    setFields({
                      ...fields,
                      toDate: date
                    });
                  }}
                  render={(_, ref) => {
                    return (
                      <Form.Floating>
                        <Form.Control
                          type="text"
                          placeholder="Project title"
                          ref={ref}
                          id="startDate"
                        />
                        <label htmlFor="startDate" className="ps-6">
                          To date
                        </label>
                      </Form.Floating>
                    );
                  }}
                />
                {fields.toDate && (
                  <Col>
                    <span
                      onClick={clearToDate}
                      style={{
                        position: 'relative',
                        bottom: 34,
                        left: 180,
                        cursor: 'pointer'
                      }}
                    >
                      <FontAwesomeIcon icon={faRemove} className="me-sm-2" />
                    </span>
                  </Col>
                )}
              </Col>

              <Col xs="auto">
                <DatePicker
                  value={fields['fromDate']}
                  options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                  onChange={([date]) => {
                    setFields({
                      ...fields,
                      fromDate: date
                    });
                  }}
                  render={(_, ref) => {
                    return (
                      <Form.Floating>
                        <Form.Control
                          type="text"
                          placeholder="Project title"
                          ref={ref}
                          id="startDate"
                        />
                        <label htmlFor="startDate" className="ps-6">
                          From date
                        </label>
                      </Form.Floating>
                    );
                  }}
                />
                {fields.fromDate && (
                  <Col xs="auto">
                    <span
                      onClick={clearFromDate}
                      style={{
                        position: 'relative',
                        bottom: 34,
                        left: 180,
                        cursor: 'pointer'
                      }}
                    >
                      <FontAwesomeIcon icon={faRemove} className="me-sm-2" />
                    </span>
                  </Col>
                )}
              </Col>
              {/* {fields.fromDate && fields.toDate && (
                <Col xs="auto">
                  <Button
                    onClick={handleGst}
                    variant="primary"
                    className="px-5"
                  >
                    Apply
                  </Button>
                </Col>
              )} */}
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>

              <Col xs="auto">
                <JsonToCsvButton
                  data={filteredData}
                  filename="GST Report.csv"
                />
                {/* <Button
                  onClick={() => console.log('clicked')}
                  variant="primary"
                  className="px-5"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" />
                  Print
                </Button> */}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default DamageList;
