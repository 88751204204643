import React from 'react';
import logo from 'assets/img/icons/logo.png';
import logoNew from 'assets/img/icons/arklogo.png';
import classNames from 'classnames';

interface LogoProps {
  width?: number;
  text?: boolean;
  textClass?: string;
  className?: string;
}

const Logo = ({
  width = 200,
  text = true,
  textClass,
  className
}: LogoProps) => {
  return (
    <div className={classNames(className, 'd-flex align-items-center')}>
      <img src={logoNew} alt="avark" style={{ width: '150px' }} />
      {text && <p className={classNames(textClass, 'logo-text ms-2')}>avark</p>}
    </div>
  );
};

export default Logo;
