import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { deleteService, getService } from 'services/services';

interface Project {
  id: number;
  supplier_name: string;
  business_name: string;
  gst_no: string;
  mobile_no: string;
  address: string;
}

const SiteApprovalListTable = () => {
  const navigate = useNavigate();
  const [supplierlList, setSupplierlList] = useState([]);
  const [supplierId, setSupplierId] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setSupplierId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`supplier/${supplierId}`);
    if (response?.status === 200) {
      setShowAlert(false);
      setSupplierId('');
      fetch();
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await getService('supplier');
    if (response.status === 200) {
      setSupplierlList(response.data);
    }
  };
  const supplireListTableColumns: ColumnDef<Project>[] = [
    {
      accessorKey: 'supplier_name',
      header: 'Site Name',
      meta: {
        cellProps: { className: 'white-space-nowrap py-4' },
        headerProps: { style: { width: '20%' } }
      }
    },
    {
      accessorKey: 'business_name',
      header: 'Site ID',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'gst_no',
      header: 'Contractor',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'address',
      header: 'Status',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'mobile_no',
      header: 'Material List View',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems
                editAction={() => navigate(`/suppliers/create-new/${id}`)}
                removeAction={() => confirmAlert(id)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];
  const table = useAdvanceTable({
    data: supplierlList,
    columns: supplireListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Supplier'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h3 className="mb-0">
            <span className="me-3">Site Material Approval List</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({supplierlList.length})
            </span>
          </h3>
          {/* <Link className="btn btn-primary px-5" to="/suppliers/create-new">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Add Supplier
          </Link> */}
        </div>
        <ProjectsTopSection activeView="list" />
        <SupplireListTable />
      </AdvanceTableProvider>
    </div>
  );
};

export const SupplireListTable = () => {
  return (
    <div className="border-bottom border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-top border-translucent fs-9'
        }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default SiteApprovalListTable;
