import { delMethode, getMethode, postMethode } from '../apiService';

// Example API request
export const getService = async (apiName: string) => {
  try {
    const response = await getMethode(apiName);
    // console.log('response==========', response);
    return response;
  } catch (error) {
    console.error(error);
  }
};

// Example API request
export const addService = async (apiName: string, payload: any) => {
  try {
    const response = await postMethode(apiName, payload);
    // console.log('response==========', response);
    return response;
  } catch (error) {
    console.error(error);
  }
};

// Example API request
export const deleteService = async (apiName: string) => {
  try {
    const response = await delMethode(apiName);
    // console.log('response==========', response);
    return response;
  } catch (error) {
    console.error(error);
  }
};
