import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import DatePicker from 'components/base/DatePicker';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import { formatDate, getStatusText } from 'helpers/utils';
import { formatDateForAPI, jsonToFormData } from 'helpers/utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { FloatingLabel, Row } from 'react-bootstrap';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, deleteService, getService } from 'services/services';

interface Stage {
  // id: number;
  name: string;
  stage_name: string;
  status: number;
  note: string;
  complete_date: any;
  completed_by_contractor_id: string;
  contractor?: any;
  id: string;
  created_at: string;
}
interface Props {
  siteId: any; // Define the prop type
  contractorFullName: string;
  contractorId: any;
}

interface Field {
  complete_date: string | Date;
  note: string;
  status: any;
  completed_by_contractor_id: string;
}

const SiteStageListTable: React.FC<Props> = props => {
  // eslint-disable-next-line react/prop-types
  const { siteId, contractorFullName, contractorId } = props;
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState('');
  const [selectedStageList, setSelectedStageList] = useState<Stage[]>([]);
  const [siteStageId, setSiteStageId] = useState('');
  const [siteStageName, setSiteStageName] = useState('');

  const [fields, setFields] = useState<Field>({
    complete_date: '',
    note: '',
    status: '',
    completed_by_contractor_id: ''
  });

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('========', event.target);
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleShow = (data: any) => {
    setSiteStageName(data.stage.stage_name);
    setShow(true);
    setSiteStageId(data.id);
    setFields({
      complete_date: data.complete_date,
      note: data.note,
      status: data.status,
      completed_by_contractor_id: data.completed_by_contractor_id
    });
  };
  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setSiteStageId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`site_stage_m/${siteStageId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setSiteStageId('');
    }
  };
  const handleClose = () => {
    setShow(false);
    setSiteStageId('');
    setError('');
  };

  const handleSave = async () => {
    fields.complete_date = formatDateForAPI(fields.complete_date);
    fields.completed_by_contractor_id = contractorId; // Add a new key-value pair directly
    const data = jsonToFormData(fields);
    let response: any = {};
    response = await addService(`site_stage_m/${siteStageId}`, data);
    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      handleClose();
    }
  };

  const stageListTableColumns: ColumnDef<Stage>[] = [
    {
      accessorKey: 'stage.stage_name',
      header: 'Stage Name',
      meta: {
        cellProps: { className: 'white-space-nowrap py-4' },
        headerProps: { style: { width: '12%' } }
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      },
      cell: ({ row: { original } }) => {
        const { status } = original;
        let backgroundColor = '';

        switch (status) {
          case 1:
            backgroundColor = 'yellow'; // Pending
            break;
          case 2:
            backgroundColor = 'red'; // Blocked
            break;
          case 3:
            backgroundColor = 'blue'; // InProgress
            break;
          case 4:
            backgroundColor = 'purple'; // QA
            break;
          case 5:
            backgroundColor = 'green'; // Completed
            break;
          default:
            backgroundColor = ''; // Default background color
            break;
        }
        return (
          <div style={{ backgroundColor }} className="ps-3 rounded-5">
            {getStatusText(status)}
          </div>
        );
      }
    },
    {
      accessorKey: 'note',
      header: 'Note',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'complete_date',
      header: 'EST. Complition date',
      cell: ({ row: { original } }) => {
        return <>{formatDate(original.complete_date)}</>;
      },
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'complete_date',
      header: 'Complition date',
      cell: ({ row: { original } }) => {
        return <>{formatDate(original.complete_date)}</>;
      },
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'contractor_id',
      header: 'Completed By',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      },
      cell: ({ row: { original } }) => {
        return (
          <>
            {original?.contractor &&
              original.contractor.first_name +
                ' ' +
                original.contractor.last_name}
          </>
        );
      }
    },
    {
      accessorKey: 'created_at',
      header: 'Created Date',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      },
      cell: ({ row: { original } }) => {
        return <>{formatDate(original.created_at)}</>;
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems
                editAction={() => handleShow(original)}
                removeAction={() => confirmAlert(id)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];
  const table = useAdvanceTable({
    data: selectedStageList,
    columns: stageListTableColumns,
    pageSize: 5,
    pagination: true,
    sortable: true
  });

  const fetch = async () => {
    const response = await getService(`site_stage_by_site_id/${siteId}`);
    if (response.status === 200) {
      setSelectedStageList(response.data);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Site Stage'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Stage List</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({selectedStageList.length})
            </span>
          </h2>
        </div>
        <ProjectsTopSection activeView="list" />
        <SupplireListTable />
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Site Stage</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="fs-9">
                      {' '}
                      STAGE NAME: {siteStageName}
                    </Form.Label>
                    <Form.Select
                      name="status"
                      value={fields['status']}
                      onChange={handleSelect}
                    >
                      <option>Select Status</option>
                      <option value="1">Pending</option>
                      <option value="2">Blocked</option>
                      <option value="3">InProgress</option>
                      <option value="4">QA</option>
                      <option value="5">Completed</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="exampleForm.ControlSelect2">
                    <Form.Label className="fs-9">Completed by</Form.Label>
                    <Form.Select>
                      <option>{contractorFullName}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6} md={6} className="mt-3">
                  <DatePicker
                    value={fields['complete_date']}
                    options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                    onChange={([date]) => {
                      setFields({
                        ...fields,
                        complete_date: date
                      });
                    }}
                    render={(_, ref) => {
                      return (
                        <Form.Floating>
                          <Form.Control
                            placeholder="Project title"
                            ref={ref}
                            id="startDate"
                          />
                          <label htmlFor="startDate" className="ps-6">
                            Select Completed date
                          </label>
                        </Form.Floating>
                      );
                    }}
                  />
                </Col>
                <Col sm={6} md={6} className="mt-3">
                  <FloatingLabel
                    controlId="floatingProjectOverview"
                    label="Note"
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: '100px' }}
                      name="note"
                      value={fields['note']}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </AdvanceTableProvider>
    </div>
  );
};

export const SupplireListTable = () => {
  return (
    <div className="border-bottom border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-top border-translucent fs-9'
        }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default SiteStageListTable;
