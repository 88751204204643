import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, FormEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import React from 'react';

interface FinalMaterial {
  id: number;
  estimated_qty?: string;
  unitdata?: {
    unit_title: string;
  };
  quantity?: string;
  material?: {
    name: string;
  };
}
interface Props {
  finalMaterialData?: FinalMaterial[]; // Define the prop type
  finalMaterialTablecolumns?: any;
}

interface Props {
  addInventory: () => void; // Define the prop type
}

// eslint-disable-next-line react/prop-types
const FinalMaterialList: React.FC<Props> = ({
  finalMaterialData,
  finalMaterialTablecolumns,
  addInventory
}) => {
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  const table = useAdvanceTable({
    data: finalMaterialData || [],
    columns: finalMaterialTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      {/* {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )} */}
      <div className="mb-9">
        <h4 className="mb-5">Final Material List</h4>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                <Button
                  onClick={addInventory}
                  variant="primary"
                  className="px-5"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Submit
                </Button>
              </Col>
            </Row>
          </div>

          <MembersTable />
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default FinalMaterialList;
