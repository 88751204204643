import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
// import PageBreadcrumb from 'components/common/PageBreadcrumb';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import IndustryMaterialListTable, {
  industryMaterialListTableColumns
} from 'components/tables/IndustryMaterialListTable';
// import { defaultBreadcrumbItems } from 'data/commonData';
// import { projects } from 'data/project-management/projects';
import { materialListDetail } from 'data/project-management/materialListDetail';
import { validationErrorFromAPI } from 'helpers/utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getService } from 'services/services';

interface Project {
  id: number;
  industry_id: number;
  material_id: number;
}

const IndustryMaterialView = () => {
  const [industryMaterialList, setIndustryMaterialList] = useState([]);

  const industryMaterialListTableColumns: ColumnDef<Project>[] = [
    {
      accessorKey: 'material.name',
      header: 'Material Name',
      meta: {
        cellProps: { className: 'white-space-nowrap py-4' },
        headerProps: { style: { width: '30%' } }
      }
    },
    {
      header: 'Industry',
      accessorKey: 'industry.name',
      meta: {
        cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
        headerProps: { style: { width: '10%' }, className: 'ps-3' }
      }
    },
    {
      id: 'action',
      cell: () => (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
          </RevealDropdown>
        </RevealDropdownTrigger>
      ),
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];
  const table = useAdvanceTable({
    data: industryMaterialList,
    columns: industryMaterialListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  useEffect(() => {
    industryMaterialFun();
  }, []);

  const industryMaterialFun = async () => {
    const response = await getService('materialIndustrie');
    if (response.status === 200) {
      setIndustryMaterialList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Industry Material List</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({industryMaterialList.length})
            </span>
          </h2>
          <Link
            className="btn btn-primary px-5"
            to="/MaterialFormat/create-new"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Create Industry Material Format
          </Link>
        </div>
        <ProjectsTopSection activeView="list" />
        <MaterialListTable />
      </AdvanceTableProvider>
    </div>
  );
};

export const MaterialListTable = () => {
  return (
    <div className="border-bottom border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-top border-translucent fs-9'
        }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};
export default IndustryMaterialView;
