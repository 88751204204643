import Button from 'components/base/Button';
import { jsonToFormData, validationErrorFromAPI } from 'helpers/utils';
import { useEffect, useState } from 'react';
// import PageBreadcrumb from 'components/common/PageBreadcrumb';
// import { defaultBreadcrumbItems } from 'data/commonData';
// import { ChangeEvent, FormEvent, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, getService } from 'services/services';

interface Industry {
  id: number;
  name: string;
}
interface Material {
  id: number;
  name: string;
}

const CreateMaterialFormat = () => {
  const navigate = useNavigate();
  const [industryList, setIndustryList] = useState<Industry[]>([]);
  const [materialList, setMaterialList] = useState<Material[]>([]);
  const [industryName, setIndustryName] = useState('');
  const [materialName, setMaterialName] = useState('');

  useEffect(() => {
    fetchIndustry();
    fetchMaterial();
  }, []);

  const fetchIndustry = async () => {
    const response = await getService('industrie');
    if (response.status === 200) {
      setIndustryList(response.data);
      // return <Redirect to="/" />;
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const fetchMaterial = async () => {
    const response = await getService('material');
    if (response.status === 200) {
      setMaterialList(response.data);
      // return <Redirect to="/" />;
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleSave = async () => {
    if (materialName === '') {
      // setError('Please enter an Material Name.');
      return;
    } else {
      const data = jsonToFormData({
        industry_id: industryName,
        material_id: materialName
      });
      const response = await addService('materialIndustrie', data);
      if (response.status === 200) {
        toast.success('SuccessFully Added', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        navigate('/industrymaterial');
        // return <Redirect to="/" />;
      } else if (response?.status === 500) {
        validationErrorFromAPI(response.data);
      }
    }

    // Add your save logic here
  };

  return (
    <div>
      {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
      <h2 className="mb-4">Industry Material</h2>
      {/* <Form onSubmit={handleSubmit}> */}
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingSelectIndustry"
                label="Industry"
              >
                <Form.Select
                  value={industryName}
                  onChange={e => setIndustryName(e.target.value)}
                >
                  <option>Select Industry</option>
                  {industryList.length > 0 &&
                    industryList.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to="/industrymaterial">
                  <Button variant="phoenix-primary" className="px-5">
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSave}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* </Form> */}
    </div>
  );
};

export default CreateMaterialFormat;
