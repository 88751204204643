/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type DamageAction = () => void;
// type RemoveAction = () => void;

interface ActionDropdownItemsProps {
  damageAction?: DamageAction; // Make the prop optional
  //   removeAction?: RemoveAction;
}

const InvDropdownItems: React.FC<ActionDropdownItemsProps> = ({
  damageAction = () => {}
  //   removeAction = () => {}
}) => {
  return (
    <>
      <Dropdown.Item eventKey="1" className="text-info" onClick={damageAction}>
        Convert into damage
      </Dropdown.Item>
      {/* <Dropdown.Item eventKey="2">Export</Dropdown.Item> */}
      {/* <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-danger"
        onClick={removeAction}
      >
        Remove
      </Dropdown.Item> */}
    </>
  );
};

export default InvDropdownItems;
