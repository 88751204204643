import Button from 'components/base/Button';
import Rating from 'components/base/Rating';
import { FloatingLabel, Form, Modal } from 'react-bootstrap';
import Dropzone from 'components/base/Dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormEvent, useEffect, useState } from 'react';
import { addService, getService } from 'services/services';
import { Col } from 'react-bootstrap';
import { jsonToFormData } from 'helpers/utils';
interface ConfirmationModalProps {
  name: string;
  show: boolean;
  siteId: number | undefined;
  handleClose: () => void;
  //   handleAction: () => void;
}

interface Project {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  mobile_no: number;
  address: string;
}
interface Common {
  id: number;
  last_name: string;
  first_name: string;
}

interface Field {
  site_id: number | undefined;
  contractor_id: string;
}

const ChangeContractorModal = ({
  name,
  show,
  siteId,
  handleClose //   handleAction
}: ConfirmationModalProps) => {
  const [contractorlList, setContractorlList] = useState<Common[]>([]);

  console.log('contractorlList', contractorlList);

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const [fields, setFields] = useState<Field>({
    site_id: siteId,
    contractor_id: ''
  });

  console.log('fields', fields);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await getService('contractor');
    if (response.status === 200) {
      // setContractorlList(response.data);
      const contractorsWithFullName = response.data.map(
        (contractor: Project) => ({
          ...contractor,
          full_name: `${contractor.first_name} ${contractor.last_name}`
        })
      );
      setContractorlList(contractorsWithFullName);
    }
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    const data = jsonToFormData(fields);
    const response = await addService('site_contractor_m', data);
    if (response.status === 200) {
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className="border-0 p-5">
        <h5 className="modal-title text-body-highlight fs-6 lh-sm">{name}</h5>
        <Button variant="phoenix-secondary" onClick={handleClose} size="sm">
          <FontAwesomeIcon icon={faTimes} className="text-danger" />
        </Button>
      </Modal.Header>
      <Modal.Body className="px-5 py-0 mb-2">
        <Col sm={6} md={6}>
          <FloatingLabel controlId="floatingSelectPrivacy" label="Country">
            <Form.Select
              name="contractor_id"
              value={fields['contractor_id']}
              onChange={handleSelect}
            >
              <option>Select Contractor</option>
              {contractorlList.length > 0 &&
                contractorlList.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.first_name} {''}
                    {item.last_name}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0 p-5">
        <Button
          variant="secondary"
          className="px-4 mr-8 text-white"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="px-4 m-0"
          //   onClick={handleAction}
          onClick={handleSave}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeContractorModal;
