import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import Section from 'components/base/Section';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  faArrowLeft,
  faDownload,
  faPrint
} from '@fortawesome/free-solid-svg-icons';
import { getService } from 'services/services';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { convertNumberToWords, formatDate } from 'helpers/utils';

interface PaymentData {
  id: number;
  currency: string;
}

interface Detail {
  siteinventory: {
    material: {
      name: string;
    };
  };
}
interface Payment {
  id: number;
  amount: number;
  created_at: any;
  mode_of_payment: number;
  transaction_no: any;
  receiver_name: string;
  invoice: {
    invoice_no: number;
    invoice_date: string;
    gst_amount: number;
    total_amount: number;
  };
  supplier: {
    address: string;
    gst_no: string;
    supplier_name: string;
    mobile_no: string;
    countrydata: {
      name: string;
    };
    statedata: {
      name: string;
    };
    citydata: {
      name: string;
    };
  };
}

const PaymentReceipt = () => {
  const navigate = useNavigate();
  const printRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const data = location.state?.data as PaymentData;

  const { id } = useParams();
  const [payments, setPayments] = useState<Partial<Payment>>({});

  const handlePrint = () => {
    if (printRef.current) {
      const printContents = printRef.current.innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload(); // To reload the page after printing
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'p') {
        event.preventDefault(); // Prevent the default print dialog
        handlePrint();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await getService(`show_invoice_payment_id/${id}`);
    if (response.status === 200) {
      setPayments(response.data);
    }
  };
  return (
    <Container
      style={{
        maxWidth: '210mm', // A4 width
        padding: '10mm', // Adjust padding as needed
        // backgroundColor: 'white', // Page background color
        boxSizing: 'border-box', // Include padding in width and height
        margin: '0 auto' // Center align on page
      }}
    >
      <div className="pt-5 pb-9 bg-body-emphasis dark__bg-gray-1200">
        <Section small className="py-0">
          <div className="d-flex gap-2 justify-content-between align-items-end mb-4">
            <Button variant="phoenix-secondary" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} className="me-sm-2" />
              <span className="d-none d-sm-inline-block">Back</span>
            </Button>
            <Button variant="phoenix-secondary" onClick={handlePrint}>
              <FontAwesomeIcon icon={faDownload} className="me-sm-2" />
              <span className="d-none d-sm-inline-block">Download Receipt</span>
            </Button>
            <Button variant="phoenix-secondary" onClick={handlePrint}>
              <FontAwesomeIcon icon={faPrint} className="me-sm-2" />
              <span className="d-none d-sm-inline-block">Print</span>
            </Button>
          </div>
          <div id="printChallan" ref={printRef}>
            <div className="print-header">
              <h4 className="text-center">Supplier Payment Receipt</h4>
            </div>
            <div className="print-header bg-body dark__bg-gray-1100 p-4 mb-4 rounded-2 border">
              <Row className="g-4">
                <Col md={6}>
                  <Row className="g-4 g-lg-2">
                    <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="mb-0 me-3">Receipt No :</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            {payments?.id}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="mb-0 me-3">Received Amount</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            {payments?.amount}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="me-3">Payment Date :</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            {formatDate(payments?.created_at)}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="me-3">Mode of Payment :</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            {payments?.mode_of_payment === 1
                              ? 'Cash'
                              : payments?.mode_of_payment === 2
                              ? 'Cheque'
                              : payments?.mode_of_payment === 3
                              ? 'Online'
                              : 'N/A'}
                          </p>
                        </Col>
                      </Row>
                    </Col> */}
                    {/* <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="me-3">Transaction ID :</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            {payments?.transaction_no ?? 'N/A'}
                          </p>
                        </Col>
                      </Row>
                    </Col> */}
                    <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="mb-0 me-3">Supplier Name :</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            {payments?.supplier?.supplier_name}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="mb-0 me-3">Contact No. :</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            {payments?.supplier?.mobile_no}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="g-4 g-lg-2">
                    <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="mb-0 me-3">Company</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            Vijay Enterprises
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="mb-0 me-3">Supplier Address :</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            {`${payments?.supplier?.address} ${payments?.supplier?.citydata?.name} ${payments?.supplier?.statedata?.name}`}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="mb-0 me-3">Email ID :</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            {payments?.invoice?.total_amount}
                          </p>
                        </Col>
                      </Row>
                    </Col> */}
                    <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="mb-0 me-3">GST No. :</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            GST123456IYGS
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="mb-0 me-3">Supplier GST :</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            {payments?.supplier?.gst_no}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col xs={12} sm={6} lg={12}>
                      <Row className=" align-items-center g-0">
                        <Col xs="auto" lg={6} xl={5}>
                          <h6 className="mb-0 me-3">Supplier Address :</h6>
                        </Col>
                        <Col xs="auto" lg={6} xl={7}>
                          <p className="fs-9 text-body-secondary fw-semibold mb-0">
                            {payments?.supplier?.address}
                          </p>
                        </Col>
                      </Row>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </div>

            {/* <EcomInvoiceTable /> */}
            {/* <PaymentReceiptTable data={challanData?.detail || []} />*/}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="text-center">Sr. No.</th>
                  <th>Particular\ Discription</th>
                  <th>Receiver Name</th>
                  <th>Mode of Payment</th>
                  <th>Transaction Id</th>
                  <th className="text-center">Amount</th>
                </tr>
              </thead>
              <tbody>
                {/* Insert table rows here */}
                <tr>
                  <td className="text-center">1</td>
                  <td>{`Invoice No. ${payments.invoice?.invoice_no}`}</td>
                  <td>{payments.receiver_name}</td>
                  <td>
                    {payments?.mode_of_payment === 1
                      ? 'Cash'
                      : payments?.mode_of_payment === 2
                      ? 'Cheque'
                      : payments?.mode_of_payment === 3
                      ? 'Online'
                      : 'N/A'}
                  </td>
                  <td>{payments?.transaction_no ?? 'N/A'}</td>
                  <td className="text-center">{payments?.amount}</td>
                </tr>
              </tbody>
            </Table>
            <p className="mt-5">
              Rupees in words:{' '}
              {payments?.amount !== undefined
                ? convertNumberToWords(payments.amount)
                    .charAt(0)
                    .toUpperCase() +
                  convertNumberToWords(payments.amount).slice(1)
                : 'Amount not available'}{' '}
              {'rupees only'}
            </p>
            <Row className="py-9 border-bottom">
              <Col>
                <h4>Authorized Signatory</h4>
              </Col>
              <Col>
                <h4 className="text-end">Receiver signature</h4>
              </Col>
            </Row>
            <div className="print-footer text-center py-4 mb-9">
              <p className="mb-0">
                This payment receipt is generated by ARK-Project ERP system |
                2024 ©{' '}
                <a href="https://avark.in//" target="_blank" rel="noreferrer">
                  Avark
                </a>
              </p>
            </div>
          </div>
        </Section>
      </div>
    </Container>
  );
};

export default PaymentReceipt;
