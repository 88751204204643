import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { dateFormat, formatDate } from 'helpers/utils';

interface Project {
  id: number;
  site_detail: any;
}

const SiteMateReqViewTable = () => {
  const [materialList, setMaterialList] = useState([]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await getService('site-performance');
    if (response.status === 200) {
      setMaterialList(response.data);
    }
  };

  const membersTablecolumns: ColumnDef<Project>[] = [
    {
      accessorKey: 'site_detail.site_name',
      header: 'Site Name'
    },
    {
      accessorKey: 'site_detail.site_id',
      header: 'Site ID'
    },
    {
      accessorKey: 'full_name',
      header: 'Contractor Name',
      cell: ({ row: { original } }) => {
        const { first_name, last_name } = original.site_detail.contractor;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {first_name} {last_name}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'Status',
      header: 'Site Current Status',
      cell: ({ row: { original } }) => {
        const { actual_end_date } = original.site_detail;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {actual_end_date === null ? 'Pending' : 'Completed'}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'Status',
      header: 'Completed Date',
      cell: ({ row: { original } }) => {
        const { actual_end_date } = original.site_detail;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {actual_end_date === null ? '' : dateFormat(actual_end_date)}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems
              // editAction={() => navigate(`/contractors/create-new/${id}`)}
              // removeAction={() => confirmAlert(id)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: materialList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <div>
      {/* {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )} */}
      <div className="mb-9">
        <h4 className="mb-5">Site Material Request</h4>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {/* <Link
                  className="btn btn-primary px-5"
                  to="/contractors/create-new"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Material
                </Link> */}
              </Col>
            </Row>
          </div>

          <MembersTable />
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default SiteMateReqViewTable;
