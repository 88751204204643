import Badge from 'components/base/Badge';
import React, { useState } from 'react';
import { Site } from 'data/project-management/projects';
import { Card, ProgressBar } from 'react-bootstrap';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvatarDropdown from 'components/common/AvatarDropdown';
import Avatar from 'components/base/Avatar';
import classNames from 'classnames';
import CardViewModal from './CardViewModal';
import { currencyFormat, dateFormat } from 'helpers/utils';
// import useProjectProgress from '../useProjectProgress';
import {
  faChevronRight,
  faCreditCard,
  faInr,
  faList,
  faListCheck,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import DatePicker from 'components/base/DatePicker';
import { Form } from 'react-bootstrap';

const CompletedSiteCardItem = ({ project }: { project: Site }) => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  console.log('project', project);

  // const { progress, bgClassName, variant } = useProjectProgress(project);
  const calculateProgress = (): number => {
    if (project.total_stage === 0) return 0; // To prevent division by zero
    return (
      ((project?.total_stage_complete ?? 0) / (project?.total_stage ?? 1)) * 100
    );
  };

  const progress: number = calculateProgress(); // Calculate progress
  const formattedProgress: string = progress.toFixed(2);
  // const progress = 0;
  const bgClassName = '';
  // const variant = '';
  // console.log("project", project)

  let variant;
  // Define conditions for different progress bar colors
  if (progress >= 0 && progress < 25) {
    variant = 'danger'; // Red color for less than 25%
  } else if (progress >= 25 && progress < 50) {
    variant = 'warning'; // Yellow color for 25% to 50%
  } else if (progress >= 50 && progress < 75) {
    variant = 'info'; // Blue color for 50% to 75%
  } else {
    variant = 'success'; // Green color for 75% and above
  }

  return (
    <>
      <Card className="h-100 hover-actions-trigger">
        <Card.Body>
          <div className="d-flex align-items-center">
            <h4 className="mb-2 line-clamp-1 lh-sm flex-1 me-5">
              {project.site_name}
            </h4>
            <div className="hover-actions top-0 end-0 mt-4 me-4">
              <Link to={`/completedSiteDetails/${project.id}`}>
                <Button
                  variant="primary"
                  className="btn-icon flex-shrink-0"
                  // onClick={() => setOpenDetailsModal(true)}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </Link>
            </div>
          </div>
          {/* <Badge
            variant="phoenix"
            bg={project.status.type}
            className="fs-10 mb-4"
          >
            {project.status.label}
          </Badge> */}
          <div className="d-flex align-items-center mb-2">
            <FontAwesomeIcon
              icon={faUser}
              className="me-2 text-body-tertiary fs-9 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1">
              Contractor :{' '}
              <span className="fw-semibold text-primary ms-1">
                {' '}
                {project?.contractor
                  ? `${project.contractor.first_name} ${project.contractor.last_name}`
                  : ''}
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center mb-2">
            <FontAwesomeIcon
              icon={faUser}
              className="me-2 text-body-tertiary fs-9 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1">
              Estimated Inventory :{' '}
              <span className="fw-semibold text-primary ms-1">
                {' '}
                View/
                <FontAwesomeIcon
                  icon={faUser}
                  className="me-2 text-body-tertiary fs-9 fw-extra-bold"
                />
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center mb-2">
            <FontAwesomeIcon
              icon={faCreditCard}
              className="me-2 text-body-tertiary fs-9 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1">
              WO Id :{' '}
              <span className="text-body-emphasis ms-1">
                {project?.workorder?.wo_id}
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center mb-2">
            <FontAwesomeIcon
              icon={faCreditCard}
              className="me-2 text-body-tertiary fs-9 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1">
              Budget :{' '}
              <span className="text-body-emphasis ms-1">
                <FontAwesomeIcon icon={faInr} /> {project.budget}/
                {project.used_budget}
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center mb-2">
            <FontAwesomeIcon
              icon={faCreditCard}
              className="me-2 text-body-tertiary fs-9 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1">
              Expenses :{' '}
              <span className="text-body-emphasis ms-1">
                <FontAwesomeIcon icon={faInr} /> {project.expenses}
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center mb-4">
            <FontAwesomeIcon
              icon={faCreditCard}
              className="me-2 text-body-tertiary fs-12 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1 d-flex align-items-center">
              Site Complete Date : {dateFormat(project.actual_end_date)}
            </p>
          </div>

          <div className="d-flex justify-content-between text-body-tertiary fw-semibold">
            <p className="mb-2"> Progress</p>
            <p className="mb-2 text-body-emphasis">
              {progress != 0 ? formattedProgress : progress}%
            </p>
          </div>
          <ProgressBar
            now={progress}
            className={classNames('flex-1', bgClassName)}
            variant={variant}

            // variant?: 'success' | 'danger' | 'warning' | 'info' | string;
          />

          <div>
            <div className="d-flex align-items-center mt-4">
              <p className="mb-0 fw-bold fs-9">
                Started :
                <span className="fw-semibold text-body-tertiary text-opactity-85 ms-1">
                  {dateFormat(project.start_date)}
                </span>
              </p>
            </div>
            <div className="d-flex align-items-center mt-2">
              <p className="mb-0 fw-bold fs-9">
                Deadline :{' '}
                <span className="fw-semibold text-body-tertiary text-opactity-85 ms-1">
                  {dateFormat(project.end_date)}
                </span>
              </p>
            </div>

            <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mt-3">
              <div className="d-flex gap-1">
                {/* <Avatar.Group total={project.assigness.length} size="m">
                  {project.assigness.slice(0, 4).map(assigne => (
                    <AvatarDropdown user={assigne} size="m" key={assigne.id} />
                  ))}
                </Avatar.Group> */}
              </div>

              <div className="mt-lg-3 mt-xl-0">
                <FontAwesomeIcon icon={faListCheck} className="me-1" />

                <p className="d-inline-block fw-bold mb-0">
                  {project.stage}
                  <span className="fw-normal"> Stage</span>
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon
                icon={faList}
                className="me-2 text-body-tertiary fs-9 fw-extra-bold"
              />
              <p className="fw-bold mb-0 text-truncate lh-1">
                Block stages :{' '}
                <span className="text-body-emphasis ms-1">
                  {project.total_stage_block}
                </span>
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default CompletedSiteCardItem;
