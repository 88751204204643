import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import { defaultBreadcrumbItems } from 'data/commonData';
import { Project, projects } from 'data/project-management/projects';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Badge } from 'react-bootstrap';
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const projectListTableColumns: ColumnDef<Project>[] = [
  {
    accessorKey: 'name',
    header: 'Dispatch Return',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <Link to="#!" className="text-decoration-none fw-bold fs-8">
          {name}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-4' },
      headerProps: { style: { width: '12%' }, className: 'fs-10' }
    }
  },
  {
    accessorKey: 'task',
    header: 'Purchase Return',
    meta: {
      cellProps: { className: 'ps-3 text-body py-4' },
      headerProps: { style: { width: '8%' }, className: 'ps-3 fs-10' }
    }
  }
];

const ReturnGoodsViewList = () => {
  const table = useAdvanceTable({
    data: projects,
    columns: projectListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Return Goods</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({projects.length})
            </span>
          </h2>
          {/* <Link className="btn btn-primary px-5" to="/CreateInventory">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Add Inventory
          </Link> */}
        </div>
        <ProjectsTopSection activeView="list" />
        <ProjectListTable />
      </AdvanceTableProvider>
    </div>
  );
};

const ProjectListTable = () => {
  return (
    <div className="border-bottom border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-top border-translucent fs-9'
        }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default ReturnGoodsViewList;
