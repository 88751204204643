import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import DatePicker from 'components/base/DatePicker';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import { defaultBreadcrumbItems } from 'data/commonData';
import { Project, projects } from 'data/project-management/projects';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Badge, Form, Modal } from 'react-bootstrap';
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const projectListTableColumns: ColumnDef<Project>[] = [
  {
    accessorKey: 'name',
    header: 'Expense',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <Link to="#!" className="text-decoration-none fw-bold fs-8">
          {name}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-4' },
      headerProps: { style: { width: '12%' }, className: 'fs-10' }
    }
  },
  {
    accessorKey: 'task',
    header: 'Date',
    meta: {
      cellProps: { className: 'ps-3 text-body py-4' },
      headerProps: { style: { width: '8%' }, className: 'ps-3 fs-10' }
    }
  },
  {
    accessorKey: 'task',
    header: 'Amount',
    meta: {
      cellProps: { className: 'ps-3 text-body py-4' },
      headerProps: { style: { width: '8%' }, className: 'ps-3 fs-10' }
    }
  },
  {
    accessorKey: 'task',
    header: 'Mode of Payment',
    meta: {
      cellProps: { className: 'ps-3 text-body py-4' },
      headerProps: { style: { width: '8%' }, className: 'ps-3 fs-10' }
    }
  },
  {
    id: 'action',
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const VoucherExpensesTable = () => {
  const [show, setShow] = useState(false);
  //     const [industryList, setIndustryList] = useState<Industry[]>([]);
  //   const [industryID, setIndustryID] = useState('');

  const table = useAdvanceTable({
    data: projects,
    columns: projectListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  const handleClose = () => {
    setShow(false);
    // setStageName('');
    // setError('');
  };

  const handleShow = () => setShow(true);

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Voucher Expenses Table</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({projects.length})
            </span>
          </h2>
          <Button onClick={handleShow} variant="primary" className="px-5">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Add Expense
          </Button>
        </div>
        <ProjectsTopSection activeView="list" />
        <ProjectListTable />
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Create voucher expense</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Select Expense</Form.Label>
                <Form.Select
                //   value={industryID}
                //   onChange={e => setIndustryID(e.target.value)}
                >
                  <option>Expense 1</option>
                  <option>Expense 2 </option>
                  <option>Expense 3 </option>
                  <option>Expense 4 </option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Add Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Amount"
                  autoFocus
                  //   value={stageName}
                  //   onChange={e => {
                  //     setStageName(e.target.value);
                  //     setError('');
                  //   }}
                  //   isInvalid={error !== ''}
                />
                <Form.Control.Feedback type="invalid">
                  {/* {error} */}
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col sm={8} md={6}>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    {/* <Form.Label>Mode of Payment</Form.Label> */}
                    <Form.Select
                    //   value={industryID}
                    //   onChange={e => setIndustryID(e.target.value)}
                    >
                      <option>Mode of Payment</option>
                      <option>Cash</option>
                      <option>Cheque </option>
                      <option>DD </option>
                      <option>NEFT </option>
                      <option>RTGS </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={6} md={4}>
                  <DatePicker
                    render={(_, ref) => {
                      return (
                        <Form.Floating>
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            ref={ref}
                            id="startDate"
                          />
                          <label htmlFor="startDate" className="ps-6">
                            Date
                          </label>
                        </Form.Floating>
                      );
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={handleSave}> */}
            <Button variant="primary">Save Changes</Button>
          </Modal.Footer>
        </Modal>
      </AdvanceTableProvider>
    </div>
  );
};

const ProjectListTable = () => {
  return (
    <div className="border-bottom border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-top border-translucent fs-9'
        }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default VoucherExpensesTable;
