import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react'; // Ensure this import is correct
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { EChartsOption } from 'echarts';
import { getService } from 'services/services';
import { Col, Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';

echarts.use([
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

interface Props {
  siteId: any;
}

// eslint-disable-next-line no-undef
const SiteStageChart: React.FC<Props> = props => {
  const { siteId } = props;
  const [compStage, setCompStage] = useState([]);
  const TotalStage = compStage.length;
  const CompleteSite = compStage.filter(
    (item: any) => item.status === 5
  ).length;
  const InCompleteStage = TotalStage - CompleteSite;

  const fetch = async () => {
    const response = await getService(`site_stage_by_site_id/${siteId}`);
    if (response.status === 200) {
      setCompStage(response.data);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  const option: EChartsOption = {
    color: ['green', 'yellow'],
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        name: 'Stage Status',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
          fontWeight: 'bold'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: CompleteSite, name: 'Complete Stage' },
          { value: InCompleteStage, name: 'Pending Stage' }
        ]
      }
    ]
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6} className="my-4">
          <Card>
            <Card.Body>
              <div style={{ width: '100%', height: '400px' }}>
                <ReactECharts option={option} />
              </div>
              <Row className="d-flex justify-content-around mt-3">
                <Col xs="auto">
                  <p>Total Stage: {TotalStage}</p>
                </Col>
                <Col xs="auto">
                  <p>Complete Stage: {CompleteSite}</p>
                </Col>
                <Col xs="auto">
                  <p>Pending Stage: {InCompleteStage}</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SiteStageChart;
