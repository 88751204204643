import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertJsonToCsv } from 'helpers/utils';
import React from 'react';
import { Button } from 'react-bootstrap';

interface JsonToCsvButtonProps {
  data: object[];
  filename: string;
}

const JsonToCsvButton: React.FC<JsonToCsvButtonProps> = ({
  data,
  filename
}) => {
  const handleDownload = () => {
    try {
      // Convert JSON data to CSV format
      const csv = convertJsonToCsv(data);
      // Create a Blob with CSV data
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      // Create a link element and set the href to the Blob URL
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      // Simulate a click to trigger the download
      link.click();
      // Cleanup: revoke the object URL
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error converting JSON to CSV:', error);
    }
  };

  //   return <button onClick={handleDownload}>Download CSV</button>;
  return (
    <Button
      variant="link"
      className="text-body me-4 px-0"
      onClick={handleDownload}
    >
      <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
      Export
    </Button>
  );
};

export default JsonToCsvButton;
