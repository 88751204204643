/* eslint-disable react/prop-types */
import React, { ChangeEvent } from 'react';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Col, Row } from 'react-bootstrap';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';

interface Material {
  id?: number;
  estimated_qty?: string;
  unit?: string;
  unitdata?: {
    unit_title: string;
  };
}
interface Props {
  materialList?: Material[]; // Define the prop type
  membersTablecolumns?: any;
}
const AllMaterialList: React.FC<Props> = React.memo(
  ({ materialList, membersTablecolumns }) => {
    const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      table.setGlobalFilter(e.target.value || undefined);
    };

    const table = useAdvanceTable({
      data: materialList || [],
      columns: membersTablecolumns,
      pageSize: 10,
      pagination: true,
      sortable: true,
      selection: false
    });

    return (
      <div>
        <div className="mb-9">
          <h4 className="mb-5">All Material List</h4>

          <AdvanceTableProvider {...table}>
            <div className="mb-4">
              <Row className="g-3">
                <Col xs="auto">
                  <SearchBox
                    placeholder="Search members"
                    onChange={handleSearchInputChange}
                  />
                </Col>
                <Col
                  xs="auto"
                  className="scrollbar overflow-hidden-y flex-grow-1"
                ></Col>
              </Row>
            </div>

            <MembersTable />
          </AdvanceTableProvider>
        </div>
      </div>
    );
  }
);

export default AllMaterialList;

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};
