import Button from 'components/base/Button';
import Rating from 'components/base/Rating';
import { Form, Modal } from 'react-bootstrap';
import Dropzone from 'components/base/Dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
interface ConfirmationModalProps {
  name: string;
  show: boolean;
  handleClose: () => void;
  handleAction: () => void;
}

const ApprovalModal = ({
  name,
  show,
  handleClose,
  handleAction
}: ConfirmationModalProps) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className="border-0 p-5">
        <h5 className="modal-title text-body-highlight fs-6 lh-sm">{`Request of ${name}`}</h5>
        <Button variant="phoenix-secondary" onClick={handleClose} size="sm">
          <FontAwesomeIcon icon={faTimes} className="text-danger" />
        </Button>
      </Modal.Header>
      <Modal.Body className="px-5 py-0 mb-2">
        <p className="d-flex justify-content-center align-items-center">
          Are you sure you want to approve this record
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0 p-5">
        <Button
          variant="secondary"
          className="px-4 mr-8 text-white"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="px-4 m-0"
          onClick={handleAction}
        >
          Approve
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApprovalModal;
