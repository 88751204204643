import Button from 'components/base/Button';
import Rating from 'components/base/Rating';
import { Form, Modal } from 'react-bootstrap';
import Dropzone from 'components/base/Dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
interface ConfirmationModalProps {
  name: string;
  show: boolean;
  handleClose: () => void;
  handleAction: () => void;
}

const ConfirmationModal = ({
  name,
  show,
  handleClose,
  handleAction
}: ConfirmationModalProps) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="border-0 p-5">
        <Modal.Title className="text-body-highlight fs-6 lh-sm">
          {`Remove ${name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5 py-0 mb-2 text-center">
        <p>Are you sure?</p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0 p-5">
        <Button
          variant="secondary"
          className="px-4 mr-8 text-white"
          onClick={handleClose}
        >
          NO
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="px-4 m-0"
          onClick={handleAction}
        >
          YES
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
