import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import Section from 'components/base/Section';
import { useEffect, useRef, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import {
  faArrowLeft,
  faDownload,
  faPrint
} from '@fortawesome/free-solid-svg-icons';
import ChallanTable from './ChallanTable';
import { getService } from 'services/services';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from 'helpers/utils';
import { Container } from 'react-bootstrap';

interface Detail {
  siteinventory: {
    quantity: number;
    material: {
      name: string;
    };
  };
}

interface Challan {
  detail?: Detail[];
  challan_no?: string;
  vehicle_no?: string;
  driver_name?: string;
  created_at?: any;
  driver_mobile?: string;
  site?: {
    site_name?: string;
    site_id?: string;
    address?: string;
    citydata?: {
      name: string;
    };
    statedata?: {
      name: string;
    };
    countrydata?: {
      name: string;
    };
  };
}

const Challan = () => {
  const printRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [challanData, setChallanData] = useState<Partial<Challan>>({});

  const handlePrint = () => {
    if (printRef.current) {
      const printContents = printRef.current.innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload(); // To reload the page after printing
    }
  };

  let totalQuantity = 0;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'p') {
        event.preventDefault(); // Prevent the default print dialog
        handlePrint();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await getService(`challan/${id}`);
    if (response.status === 200) {
      setChallanData(response.data);
    }
  };
  return (
    <Container
      style={{
        maxWidth: '210mm', // A4 width
        padding: '10mm', // Adjust padding as needed
        // backgroundColor: 'white', // Page background color
        boxSizing: 'border-box', // Include padding in width and height
        margin: '0 auto' // Center align on page
      }}
    >
      <div className="d-flex gap-2 justify-content-between align-items-end mb-4">
        <h2 className="mb-0 flex-1">Challan</h2>
        <Button variant="phoenix-secondary" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} className="me-sm-2" />
          <span className="d-none d-sm-inline-block">Back</span>
        </Button>
        <Button variant="phoenix-secondary" onClick={handlePrint}>
          <FontAwesomeIcon icon={faDownload} className="me-sm-2" />
          <span className="d-none d-sm-inline-block">Download Challan</span>
        </Button>
        <Button variant="phoenix-secondary" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} className="me-sm-2" />
          <span className="d-none d-sm-inline-block">Print</span>
        </Button>
      </div>
      <div id="printChallan" ref={printRef}>
        <h3 className="text-center py-2">CHALLAN</h3>
        <div className="border mb-5">
          <div className="d-flex ps-3  py-2">
            <text style={{ width: '40%', fontSize: 14 }}>
              Challan No: {challanData.challan_no}
            </text>
            <text style={{ width: '30%', fontSize: 14 }} className="ms-5">
              Date: {formatDate(challanData.created_at)}
            </text>
            <text style={{ width: '30%', fontSize: 14 }}>{}</text>
          </div>
          <div className="d-flex ps-3  py-2">
            <text style={{ width: '40%', fontSize: 14 }}>
              Site Name: {challanData?.site?.site_name}
            </text>
            <text style={{ width: '30%', fontSize: 14 }} className="ms-5">
              Site Id: {challanData?.site?.site_id}
            </text>
            <text style={{ width: '30%', fontSize: 14 }}>
              Address: {challanData?.site?.address}{' '}
              {challanData?.site?.citydata?.name}{' '}
              {challanData?.site?.statedata?.name}{' '}
              {challanData?.site?.countrydata?.name}
            </text>
          </div>
          <div className="d-flex ps-3  py-2">
            <text style={{ width: '40%', fontSize: 14 }}>
              Vehicle No: {challanData.vehicle_no}
            </text>
            <text style={{ width: '30%', fontSize: 14 }} className="ms-5">
              Driver Name: {challanData.driver_name}
            </text>
            <text style={{ width: '30%', fontSize: 14 }}>
              Contact No: {challanData.driver_mobile}
            </text>
          </div>
        </div>
        <Table bordered responsive className="custom-table">
          <thead>
            <tr>
              <th>SR NO.</th>
              <th>Material Name</th>
              <th className="text-center">Unit</th>
              <th className="text-center">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {challanData?.detail &&
              challanData?.detail?.map((item: any, index) => {
                totalQuantity += item.siteinventory.quantity;
                return (
                  <tr key={index}>
                    <td className="text-center py-1">{index + 1}</td>
                    <td className="py-1">{item.siteinventory.material.name}</td>
                    <td className="text-center py-1">
                      {item.siteinventory.material.unitdata.unit_title}
                    </td>
                    <td className="text-center py-1">
                      {item.siteinventory.quantity}
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td colSpan={3} className="text-end">
                <strong className="text-center">Total Quantity</strong>
              </td>
              <td className="text-center">
                <strong>{totalQuantity}</strong>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="text-end py-9 border-bottom">
          <h4>Authorized Signatory</h4>
        </div>
        <div className="print-footer text-center py-4 mb-9">
          <p className="mb-0">
            This challan is generated by ARK-Project ERP system | 2024 ©
            <a href="https://avark.in//" target="_blank" rel="noreferrer">
              Avark
            </a>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Challan;
