import { postMethode } from '../apiService';

// Example API request
export const loginService = async (payload: any) => {
  try {
    const response = await postMethode('login', payload);
    // console.log('response==========', response);
    return response;
  } catch (error) {
    console.error(error);
  }
};
