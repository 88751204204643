/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type EditAction = () => void;
type ApprovalAction = () => void;
type RejectAction = () => void;

interface ActionDropdownItemsProps {
  editAction?: EditAction; // Make the prop optional
  approvalAction?: ApprovalAction;
  rejectAction?: RejectAction;
}

const ApprovalDropdownItems: React.FC<ActionDropdownItemsProps> = ({
  editAction = () => {},
  approvalAction = () => {},
  rejectAction = () => {}
}) => {
  return (
    <>
      <Dropdown.Item eventKey="1" onClick={editAction}>
        Edit
      </Dropdown.Item>
      {/* <Dropdown.Item eventKey="2">Export</Dropdown.Item> */}
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-success"
        onClick={approvalAction}
      >
        Approve
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-danger"
        onClick={rejectAction}
      >
        Reject
      </Dropdown.Item>
    </>
  );
};

export default ApprovalDropdownItems;
