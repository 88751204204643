import Badge from 'components/base/Badge';
import React, { FormEvent, useEffect, useState } from 'react';
import { Site } from 'data/project-management/projects';
import { Card, ProgressBar } from 'react-bootstrap';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvatarDropdown from 'components/common/AvatarDropdown';
import Avatar from 'components/base/Avatar';
import classNames from 'classnames';
import CardViewModal from './CardViewModal';
import {
  currencyFormat,
  dateFormat,
  formatDateForAPI,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
// import useProjectProgress from '../useProjectProgress';
import {
  faChevronRight,
  faCreditCard,
  faInr,
  faList,
  faListCheck,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import DatePicker from 'components/base/DatePicker';
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { addService, getService } from 'services/services';
import { toast } from 'react-toastify';

interface Field {
  wo_id: string;
  site_name: string;
  site_id: string;
  country: string;
  state: string;
  city: string;
  address: string;
  contractor_id: string;
  start_date: string | Date;
  end_date: string | Date;
  budget: string;
  description: string;
  actual_start_date?: string | Date; // Make optional with '?'
  actual_end_date?: string | Date; // Make optional with '?'
}

const SiteCardViewItem = ({ project }: { project: Site }) => {
  const navigate = useNavigate();
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [date, setDate] = useState<Date | undefined>(undefined); // State to hold selected date

  const fetchDetails = async (id: any, formattedDate: any) => {
    try {
      const response = await getService(`site/${id}`);
      if (response.status === 200) {
        const addServiceResponse = await addService(`site/${id}`, {
          wo_id: response.data.detail.wo_id,
          site_name: response.data.detail.site_name,
          site_id: response.data.detail.id,
          contractor_id: response.data.detail.contractor_id,
          start_date: response.data.detail.start_date,
          end_date: response.data.detail.end_date,
          budget: response.data.detail.budget,
          description: response.data.detail.description,
          country: response.data.detail.country,
          state: response.data.detail.state,
          city: response.data.detail.city,
          address: response.data.detail.address,
          actual_end_date: formattedDate
        });

        if (addServiceResponse?.status === 200) {
          toast.success('Successfully Added', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          });
          navigate('/completedSiteList');
        } else {
          toast.error('Error', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          });
          validationErrorFromAPI(addServiceResponse.data);
        }
      } else if (response?.status === 500) {
        validationErrorFromAPI(response.data);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle error as needed
    }
  };

  const handleDateChange = async (
    selectedDate: Date | Date[] | undefined,
    id: any
  ) => {
    if (selectedDate === undefined) {
      setDate(undefined); // Reset date if no date is selected
      return; // Exit function early
    }

    const startDate = Array.isArray(selectedDate)
      ? selectedDate[0]
      : selectedDate;
    setDate(startDate);
    const formattedDate = formatDateForAPI(startDate);

    if (id && formattedDate) {
      fetchDetails(id, formattedDate);
    } else {
      alert('Id not found');
    }
  };

  const calculateProgress = (): number => {
    if (project.total_stage === 0) return 0; // To prevent division by zero
    return (
      ((project?.total_stage_complete ?? 0) / (project?.total_stage ?? 1)) * 100
    );
  };

  const progress: number = calculateProgress(); // Calculate progress
  const formattedProgress: string = progress.toFixed(2);
  // const progress = 0;
  const bgClassName = '';
  // const variant = '';
  // console.log("project", project)

  let variant;
  // Define conditions for different progress bar colors
  if (progress >= 0 && progress < 25) {
    variant = 'danger'; // Red color for less than 25%
  } else if (progress >= 25 && progress < 50) {
    variant = 'warning'; // Yellow color for 25% to 50%
  } else if (progress >= 50 && progress < 75) {
    variant = 'info'; // Blue color for 50% to 75%
  } else {
    variant = 'success'; // Green color for 75% and above
  }

  const currentDate = new Date();

  const projectEndDate = new Date(project.end_date);

  const differenceInTime = projectEndDate.getTime() - currentDate.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  let deadlineColorClass = '';

  if (differenceInDays < 0) {
    deadlineColorClass = 'text-success'; // Green color for deadline under current date
  } else if (differenceInDays <= 3) {
    deadlineColorClass = 'text-warning'; // Orange color for 3 or fewer days left
  } else if (differenceInDays <= 5) {
    deadlineColorClass = 'text-yellow'; // Yellow color for 5 or fewer days left
  } else {
    deadlineColorClass = 'text-body-tertiary'; // Default color for more than 5 days left
  }

  const isProgressNot100 = progress !== 100 && differenceInDays < 0;

  return (
    <>
      <Card className="h-100 hover-actions-trigger">
        <Card.Body>
          <div className="d-flex align-items-center">
            <h4 className="mb-2 line-clamp-1 lh-sm flex-1 me-5">
              {project.site_name}
            </h4>
            <div className="hover-actions top-0 end-0 mt-4 me-4">
              <Link to={`/SiteDetails/${project.id}`}>
                <Button
                  variant="primary"
                  className="btn-icon flex-shrink-0"
                  // onClick={() => setOpenDetailsModal(true)}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </Link>
            </div>
          </div>
          {/* <Badge
            variant="phoenix"
            bg={project.status.type}
            className="fs-10 mb-4"
          >
            {project.status.label}
          </Badge> */}
          <div className="d-flex align-items-center mb-2">
            <FontAwesomeIcon
              icon={faUser}
              className="me-2 text-body-tertiary fs-9 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1">
              Contractor :{' '}
              <span className="fw-semibold text-primary ms-1">
                {' '}
                {project?.contractor
                  ? `${project.contractor.first_name} ${project.contractor.last_name}`
                  : ''}
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center mb-2">
            <FontAwesomeIcon
              icon={faUser}
              className="me-2 text-body-tertiary fs-9 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1">
              Estimated Inventory :{' '}
              <span className="fw-semibold text-primary ms-1">
                {' '}
                View/
                <FontAwesomeIcon
                  icon={faUser}
                  className="me-2 text-body-tertiary fs-9 fw-extra-bold"
                />
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center mb-2">
            <FontAwesomeIcon
              icon={faCreditCard}
              className="me-2 text-body-tertiary fs-9 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1">
              WO Id :{' '}
              <span className="text-body-emphasis ms-1">
                {project?.workorder?.wo_id}
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center mb-2">
            <FontAwesomeIcon
              icon={faCreditCard}
              className="me-2 text-body-tertiary fs-9 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1">
              Budget :{' '}
              <span className="text-body-emphasis ms-1">
                <FontAwesomeIcon icon={faInr} /> {project.budget}/
                {project.used_budget}
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center mb-2">
            <FontAwesomeIcon
              icon={faCreditCard}
              className="me-2 text-body-tertiary fs-9 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1">
              Expenses :{' '}
              <span className="text-body-emphasis ms-1">
                <FontAwesomeIcon icon={faInr} /> {project.expenses}
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center mb-4">
            {progress == 100 ? (
              <>
                <FontAwesomeIcon
                  icon={faCreditCard}
                  className="me-2 text-body-tertiary fs-12 fw-extra-bold"
                />
                <p className="fw-bold mb-0 text-truncate lh-1 d-flex align-items-center">
                  Site Complete :{' '}
                  <Col sm={4} md={6} className="ms-2">
                    <DatePicker
                      value={date}
                      onChange={(selectedDate: Date | Date[] | undefined) =>
                        handleDateChange(selectedDate, project.id)
                      }
                      placeholder="Select Date"
                      options={{ enableTime: false, dateFormat: 'Y-m-d' }} // Use options prop to set format
                      // calendarIcon={null}
                    />
                    {/* <label className="ps-6">Select Date</label> */}
                  </Col>
                </p>
              </>
            ) : (
              <div className="d-flex align-items-center mb-6"></div>
            )}
          </div>

          <div className="d-flex justify-content-between text-body-tertiary fw-semibold">
            <p className="mb-2"> Progress</p>
            <p className="mb-2 text-body-emphasis">
              {progress != 0 ? formattedProgress : progress}%
            </p>
          </div>
          <ProgressBar
            now={progress}
            className={classNames('flex-1', bgClassName)}
            variant={variant}
          />

          <div>
            <div className="d-flex align-items-center mt-4">
              <p className="mb-0 fw-bold fs-9">
                Started :
                <span
                  // className="fw-semibold text-body-tertiary text-opactity-85 ms-1"
                  style={{ color: 'lightgreen' }}
                >
                  {' '}
                  {dateFormat(project.start_date)}
                </span>
              </p>
            </div>
            <div className="d-flex align-items-center mt-2">
              <p className="mb-0 fw-bold fs-9">
                Deadline :{' '}
                <span
                  className={`fw-semibold  text-opactity-85 ms-1`}
                  style={{
                    color: isProgressNot100
                      ? 'red'
                      : deadlineColorClass === 'text-success'
                      ? 'green'
                      : deadlineColorClass === 'text-warning'
                      ? 'orange'
                      : deadlineColorClass === 'text-yellow'
                      ? 'yellow'
                      : 'inherit'
                  }}
                >
                  {dateFormat(project.end_date)}
                </span>
              </p>
            </div>

            <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mt-3">
              <div className="d-flex gap-1"></div>

              <div className="mt-lg-3 mt-xl-0">
                <FontAwesomeIcon icon={faListCheck} className="me-1" />

                <p className="d-inline-block fw-bold mb-0">
                  {project.stage}
                  <span className="fw-normal"> Stage</span>
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon
                icon={faList}
                className="me-2 text-body-tertiary fs-9 fw-extra-bold"
              />
              <p className="fw-bold mb-0 text-truncate lh-1">
                Block stages :{' '}
                <span
                  className={`ms-1 ${
                    project.total_stage_block > 0
                      ? 'text-danger'
                      : 'text-success'
                  }`}
                >
                  {project.total_stage_block}
                </span>
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default SiteCardViewItem;
