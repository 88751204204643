import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Col, Form, Modal, Row, ToggleButtonGroup } from 'react-bootstrap';
import {
  faFileExport,
  faPaperclip,
  faPlus,
  faRemove
} from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  dateFormat,
  formatDateForAPI,
  jsonToFormData,
  openInNewTab,
  validationErrorFromAPI
} from 'helpers/utils';
import { FloatingLabel } from 'react-bootstrap';
import DatePicker from 'components/base/DatePicker';
import { toast } from 'react-toastify';
import { ToggleButton } from 'react-bootstrap';
import { baseLocalURL } from 'config';

interface Project {
  id: number;
  status: number;
  expensemaster: {
    expense_title: string;
  };
  comments: string;
  amount: string;
  bill_doc: any;
  expenses_date: string;
  mode_of_payment: string;
}

interface ExpensesItem {
  id: number;
  expense_title: string;
}

interface Field {
  site_id: number;
  expense_id: string;
  amount: string;
  paid_amount: string;
  comments: string;
  mode_of_payment: string;
  expenses_date: string | Date;
  status: any;
  bill_doc: any;
}

interface Material {
  id: number;
  expense_title: string;
}
interface Props {
  siteId: any;
}

// eslint-disable-next-line react/prop-types
const SiteExpenseListTable: React.FC<Props> = ({ siteId }) => {
  const [show, setShow] = useState(false);
  const [expensesList, setExpensesList] = useState([]);
  const [expAmount, setExpAmount] = useState('');
  const [uniqueExp, setUniqueExp] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [expenseId, setExpenseId] = useState('');
  const [expenseList, setExpenseList] = useState<Material[]>([]);
  const [image, setImage] = useState<File | null>(null);
  const [imageApi, setImageApi] = useState<File | null>(null);
  const [expStatus, setExpStatus] = useState();
  const [role, setRole] = useState(0);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const uniqueNames = new Set<string>();

  const handleExpChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedExpenseId = event.target.value;
    setUniqueExp(selectedExpenseId);

    // If the selected value is empty, skip the API call
    if (!selectedExpenseId) {
      // return;
      fetch();
    }

    try {
      const response = await getService(
        `site_master_expenses_by_expenses_id_site_id/${selectedExpenseId}/${siteId}`
      );
      if (response.status === 200) {
        setExpensesList(response.data);
        setExpAmount(response.data[0]?.total_amount);
      } else if (response?.status === 500) {
        toast.error('Error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        validationErrorFromAPI(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImage(file);
      setImageApi(null);
    }
  };

  const [fields, setFields] = useState<Field>({
    site_id: siteId,
    expense_id: '',
    amount: '',
    paid_amount: '',
    comments: '',
    mode_of_payment: '',
    expenses_date: '',
    bill_doc: '',
    status: 0
  });

  console.log('fields', fields.status);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleShow = (data: any) => {
    setShow(true);
    setExpenseId(data.id);
    setExpStatus(data.status);
    setFields({
      site_id: siteId,
      expense_id: data.expense_id,
      amount: data.amount,
      paid_amount: data.paid_amount,
      comments: data.comments,
      mode_of_payment: data.mode_of_payment,
      expenses_date: data.expenses_date,
      bill_doc: data.bill_doc,
      status: role == 1 || role == 2 ? data.status : 0
    });
    setImageApi(data.bill_doc);
  };

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setExpenseId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`site_master_expenses/${expenseId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setExpenseId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleClose = () => {
    setShow(false);
    setExpenseId('');
    // setIndustryName('');
    // setError('');
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    fields.bill_doc = image ? image : imageApi;
    fields.expenses_date = formatDateForAPI(fields.expenses_date);
    const data = jsonToFormData(fields);
    let response: any = {};
    if (expenseId) {
      response = await addService(`site_master_expenses/${expenseId}`, data);
    } else {
      response = await addService(`site_master_expenses`, data);
    }
    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      handleClose();
    } else if (response?.status === 500) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };

  useEffect(() => {
    fetch();
    fetchExpense();
  }, []);

  const fetch = async () => {
    const response = await getService(
      `site_master_expenses_by_site_id/${siteId}`
    );
    if (response.status === 200) {
      setExpensesList(response.data);
    }
  };

  const fetchExpense = async () => {
    const response = await getService(`expenseMaster`);
    if (response.status === 200) {
      setExpenseList(response.data);
    }
  };

  const paymentModes = {
    '1': 'Online',
    '2': 'Cash',
    '3': 'Cheque',
    '4': 'DD'
  };

  const handleChangeStatus = (val: string) => {
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      status: val // Update the specific field with the new value
    }));
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Project>[] = [
    {
      accessorKey: 'expensemaster.expense_title',
      header: 'Expense Title',
      cell: ({ row: { original } }) => {
        const {
          expensemaster: { expense_title }
        } = original;
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {expense_title ?? 'Expense Title'}
            </p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'expenses_date',
      header: 'Date',
      cell: ({ row: { original } }) => {
        const { expenses_date } = original;
        return (
          <span className="text-body-secondary fs-9 mb-0">
            {expenses_date ? dateFormat(expenses_date) : ''}
          </span>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'paid_amount',
      header: 'Paid Amount',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'mode_of_payment',
      header: 'Payment Mode',
      cell: ({ row: { original } }) => {
        const { mode_of_payment } = original;
        const modeOfPayment =
          paymentModes[mode_of_payment as keyof typeof paymentModes];
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {modeOfPayment}
            </p>
          </Link>
        );
      },
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'comments',
      header: 'Note',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row: { original } }) => {
        const { status } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {status === 0
              ? 'Pending'
              : status === 1
              ? 'Approved'
              : status === 2
              ? 'Rejected'
              : 'Unknown Status'}
          </p>
        );
      },
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'bill_doc',
      header: 'Document',
      cell: ({ row: { original } }) => {
        const { bill_doc } = original;
        return (
          <Link
            to="#"
            onClick={() => openInNewTab(`${baseLocalURL}/public/${bill_doc}`)}
          >
            {bill_doc && (
              <img
                src={`${baseLocalURL}/public/${bill_doc}`}
                width="40"
                height="40"
                style={{ borderRadius: 20 }}
                alt="Document"
              />
            )}
          </Link>
        );
      },
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id, status } = original;
        if (status === 0 && role === 4) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <ActionDropdownItems
                  editAction={() => handleShow(original)}
                  removeAction={() => confirmAlert(id)}
                />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else if (
          (status === 1 || status === 2 || status === 0) &&
          (role === 1 || role === 3)
        ) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <ActionDropdownItems
                  editAction={() => handleShow(original)}
                  removeAction={() => confirmAlert(id)}
                />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: expensesList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Expense'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h4 className="mb-5">Site Expense</h4>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs="auto">
                <FloatingLabel
                  controlId="floatingSelectTask"
                  label="Expense Title"
                >
                  <Form.Select
                    name="Expense Title"
                    value={uniqueExp}
                    onChange={handleExpChange}
                  >
                    <option value="">Select Expense</option>
                    {expenseList.length > 0 &&
                      expenseList.map((item: ExpensesItem) => {
                        if (!uniqueNames.has(item.expense_title)) {
                          uniqueNames.add(item.expense_title);
                          return (
                            <option key={item.id} value={item.id}>
                              {item.expense_title}
                            </option>
                          );
                        }
                        return null;
                      })}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Total Expense Amount"
                >
                  <Form.Control
                    type="number"
                    placeholder="Amount"
                    name="amount"
                    value={expensesList.length != 0 ? expAmount : ''}
                    // onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>

              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                <Button onClick={handleShow} variant="primary" className="px-5">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Site Expense
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              {' '}
              <Modal.Title>Edit Site Expense</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingSelectTask"
                        label="Expense"
                      >
                        <Form.Select
                          name="expense_id"
                          value={fields['expense_id']}
                          onChange={handleSelect}
                        >
                          <option>Select Expense</option>
                          {expenseList.length > 0 &&
                            expenseList.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.expense_title}
                              </option>
                            ))}
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Amount"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Amount"
                          name="amount"
                          value={fields['amount']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Paid Amount"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Paid Amount"
                          name="paid_amount"
                          value={fields['paid_amount']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingSelectTask"
                        label="Payment Mode"
                      >
                        <Form.Select
                          name="mode_of_payment"
                          value={fields['mode_of_payment']}
                          onChange={handleSelect}
                        >
                          <option>Select payment type </option>
                          <option value="1">Online</option>
                          <option value="2">Cash</option>
                          <option value="3">Cheque</option>
                          <option value="4">DD</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <DatePicker
                        value={fields['expenses_date']}
                        options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                        onChange={([date]) => {
                          setFields({
                            ...fields,
                            expenses_date: date
                          });
                        }}
                        render={(_, ref) => {
                          return (
                            <Form.Floating>
                              <Form.Control
                                type="text"
                                placeholder="Project title"
                                ref={ref}
                                id="startDate"
                              />
                              <label htmlFor="startDate" className="ps-6">
                                Select date
                              </label>
                            </Form.Floating>
                          );
                        }}
                      />
                    </Col>
                    <Col sm={6} md={6} className="gy-3">
                      <FloatingLabel
                        controlId="floatingProjectOverview"
                        label="Note"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Leave a comment here"
                          name="comments"
                          value={fields['comments']}
                          onChange={handleChange}
                          style={{ height: '100px' }}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={4} md={4}>
                      <div className="d-flex align-items-center border rounded-2 pl-2 cursor-pointer">
                        <div className="p-2 d-flex align-items-center">
                          <label
                            className="text-body fs-9 cursor-pointer"
                            htmlFor="attachments"
                          >
                            <FontAwesomeIcon
                              icon={faPaperclip}
                              className="me-1"
                            />
                            <span className="ml-2">Upload Image</span>
                          </label>
                        </div>
                        <Form.Control
                          className="d-none"
                          type="file"
                          id="attachments"
                          onChange={handleImageChange}
                        />
                      </div>
                      {imageApi && (
                        <div className="mt-3 border rounded position-relative">
                          <img
                            src={`${baseLocalURL}/public/${imageApi}`}
                            alt="Uploaded"
                            style={{ maxWidth: '100%' }}
                          />
                          <FontAwesomeIcon
                            onClick={() => [setImage(null), setImageApi(null)]}
                            icon={faRemove}
                            className="position-absolute top-0 end-0 m-1 bg-black px-1 rounded-2"
                          />
                        </div>
                      )}
                      {image && (
                        <div className="mt-3 border rounded position-relative">
                          <img
                            src={URL.createObjectURL(image)}
                            alt="Uploaded"
                            style={{ maxWidth: '100%' }}
                          />
                          <FontAwesomeIcon
                            onClick={() => [setImage(null), setImageApi(null)]}
                            icon={faRemove}
                            className="position-absolute top-0 end-0 m-1 bg-black px-1 rounded-2"
                          />
                        </div>
                      )}
                    </Col>
                    {expenseId && (role == 1 || role == 3) && (
                      <>
                        <span>STATUS</span>
                        <ToggleButtonGroup
                          type="radio"
                          name="options"
                          value={fields['status']}
                          onChange={handleChangeStatus}
                        >
                          <ToggleButton
                            id="1"
                            variant="outline-primary"
                            value="1"
                            checked={expStatus === 1}
                          >
                            Approve
                          </ToggleButton>
                          <ToggleButton
                            id="2"
                            variant="outline-primary"
                            value="2"
                            checked={expStatus === 2}
                          >
                            Reject
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default SiteExpenseListTable;
