/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type EditAction = () => void;
type PrintAction = () => void;
type PaymentStatusAction = () => void;

interface ActionDropdownItemsProps {
  editAction?: EditAction; // Make the prop optional
  printAction?: PrintAction;
  paymentStatusAction?: PaymentStatusAction;
}

const WoInvoiceDropdownItems: React.FC<ActionDropdownItemsProps> = ({
  editAction = () => {},
  printAction = () => {},
  paymentStatusAction = () => {}
}) => {
  return (
    <>
      <Dropdown.Item eventKey="1" className="text-info" onClick={editAction}>
        Action
      </Dropdown.Item>
      {/* <Dropdown.Item eventKey="2">Export</Dropdown.Item> */}
      {/* <Dropdown.Divider />
      <Dropdown.Item eventKey="2" className="text-info" onClick={printAction}>
        Print
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-info"
        onClick={paymentStatusAction}
      >
        Payment Status
      </Dropdown.Item> */}
    </>
  );
};

export default WoInvoiceDropdownItems;
