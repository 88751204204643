import React from 'react';
import { Container, Row, Col, Table, Image } from 'react-bootstrap';

const ProfarmaInvoice = () => {
  return (
    <Container className="mt-4">
      <Table bordered>
        <thead>
          <tr>
            <th className="logo">
              <Image
                src="images/logo1.png"
                alt="Company Logo"
                width="100"
                height="50"
              />
            </th>
            <th colSpan={7} className="company-name">
              <div className="company-details">
                <h2>Shri Ram Sagar Construction</h2>
                <p>Plot No.6 Omax City 2 Mangliya Road Indore (M.P.) 453771</p>
                <h6>Email: shriramsagarconstruction@gmail.com</h6>
                <h6>Phone: 9826444603</h6>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="tg-0laxx" colSpan={8}>
              PROFARMA INVOICE
            </th>
          </tr>
          <tr>
            <td className="tg-0lax" colSpan={8}></td>
          </tr>
          <tr>
            <td className="tg-0la1" colSpan={2}>
              CORPORATE OFFICE
            </td>
            <td className="tg-0lax" colSpan={2}>
              PLOT NO. 6 OMAX-CITY 2 MANGLIYA ROAD INDORE(M.P) 453771
            </td>
            <td className="tg-0la1" rowSpan={2}>
              <br />
              BRANCH
              <br />
              OFFICE
            </td>
            <td className="tg-0lax" colSpan={3} rowSpan={2}>
              <br />
              PLOT NO. 6 OMAX- CITY 2 MANGLIYA ROAD INDORE(M.P) 453771
              <br />
            </td>
          </tr>
          <tr>
            <td className="tg-0la1" colSpan={2}>
              SUPPLIE'S GSTIN
            </td>
            <td className="tg-0lax" colSpan={2}>
              23ABXFS1446C1Z5
            </td>
          </tr>
          {/* More rows go here */}
          {/* Example row, fill in with your actual data */}
          <tr>
            <td className="tg-0la1" colSpan={4}>
              TO,
            </td>
            <td className="tg-0la1">
              INVOICE NO/ INVOICE
              <br />
              DATE
            </td>
            <td className="tg-0lax" colSpan={2}>
              SRS/MP/24-25/03
            </td>
            <td className="tg-0lax">1-APR-24</td>
          </tr>
          {/* Continue adding rows */}
        </tbody>
      </Table>
    </Container>
  );
};

export default ProfarmaInvoice;
