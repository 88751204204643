import {
  faPaperclip,
  faPlus,
  faRemove
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import ReactSelect from 'components/base/ReactSelect';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { defaultBreadcrumbItems } from 'data/commonData';
import { calculateGST, validationErrorFromAPI } from 'helpers/utils';
import { ChangeEvent, useEffect, useState } from 'react';
import { Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, deleteService, getService } from 'services/services';

interface Supplier {
  id: number;
  supplier_name: string;
}
interface StateGST {
  id: number;
  name: string;
}
interface Material {
  id: any;
  name: string;
  unitdata?: any;
}

interface SupplierField {
  id?: number | string;
  supplier_id: number | string;
  invoice_no?: any;
  material_id?: any;
  barcode?: any;
  batch_no?: any;
  stock?: any;
  purchase_rate?: any;
  gst?: any;
  gst_amt?: any;
  total_amt?: any;
}
interface Field {
  id?: number | string;
  material_id?: any;
  unit?: number | string;
  barcode?: any;
  batch_no?: any;
  maintan_date?: any;
  stock?: any;
  purchase_rate?: any;
  gst?: any;
  gst_amt?: any;
  total_amt?: any;

  // quantity?: any;
  // rate_per_site?: any;
  // site_rate?: any;
  // tpgst?: any;
}

interface FormData {
  supplier_id: string;
  invoice_no: string;
  invoice_date: string | Date;
  note: string;
  invoice_document: any;
  sbu_id: string;
}
const CreateInventory = () => {
  const navigate = useNavigate();
  const [supplierList, setSupplierList] = useState<Supplier[]>([]);
  const [stateList, setStateList] = useState<StateGST[]>([]);
  const [materialList, setMaterialList] = useState<Material[]>([]);
  const [image, setImage] = useState<File | null>(null);
  const [grandTotalGst, setGrandTotalGst] = useState<number>(0);
  const [grandTotalAmount, setGrandTotalAmount] = useState<number>(0);
  const [fields, setFields] = useState<Field[]>([
    {
      material_id: '',
      unit: '',
      barcode: '',
      batch_no: '',
      maintan_date: '',
      stock: '',
      purchase_rate: '',
      gst: '',
      gst_amt: '',
      total_amt: ''
    }
  ]);

  const [formData, setFormData] = useState<FormData>({
    supplier_id: '',
    invoice_no: '',
    invoice_date: '',
    note: '',
    invoice_document: '',
    sbu_id: ''
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImage(file);
      setFormData(prevFields => ({
        ...prevFields,
        invoice_document: file
      }));
    }
  };

  const handleAddField = () => {
    const newObj: Field = {
      // id: '',
      material_id: '',
      unit: '',
      barcode: '',
      batch_no: '',
      maintan_date: '',
      stock: '',
      purchase_rate: '',
      gst: '',
      gst_amt: '',
      total_amt: ''
    };
    // Add newObj to originalArray
    // const originalfield: Field[] = fields.concat(newObj);
    const originalfield: Field[] = [newObj, ...fields];
    setFields(originalfield);
  };

  const handleRemoveField = async (indexToRemove: number, id?: any) => {
    // Filter out the object to remove based on its index
    const updatedFields: Field[] = fields.filter(
      (_, index) => index !== indexToRemove
    );
    // Update the state with the filtered array
    setFields(updatedFields);
    // if (id) {
    //   await deleteService(`site_inventory/${id}`);
    // }
  };

  const handleSelect = (
    event: ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const { value } = event.target;
    const foundMaterial = materialList.find(material => material.id == value);
    // Make a shallow copy of the array of fields
    const fieldset = [...fields];
    // // Make a shallow copy of the specific field object within the array
    const updatedField = { ...fieldset[index] };
    // // Update the specific property within the copied field object
    updatedField['material_id'] = value;
    updatedField['unit'] = foundMaterial?.unitdata?.unit_title;
    // // // Update the array with the updated field object at the specified index
    fieldset[index] = updatedField;
    // // // Set the state with the updated array
    setFields(fieldset);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const fieldset = [...fields];
    const updatedField = { ...fieldset[index] };
    const fieldName = name as keyof Field;
    updatedField[fieldName] = value;

    if (
      fieldName === 'stock' ||
      fieldName === 'purchase_rate' ||
      fieldName === 'gst'
    ) {
      const stock = parseFloat(updatedField['stock']) || 0;
      const purchaseRate = parseFloat(updatedField['purchase_rate']) || 0;
      const gst = parseFloat(updatedField['gst']) || 0;

      const site_rate = stock * purchaseRate;
      const gstAmount = (site_rate * gst) / 100;
      const totalAmount = site_rate + gstAmount;

      // updatedField['site_rate'] = site_rate.toFixed(2);
      updatedField['gst_amt'] = gstAmount.toFixed(2);
      updatedField['total_amt'] = totalAmount.toFixed(2);
    }

    fieldset[index] = updatedField;
    setFields(fieldset);

    // Recalculate grand totals
    const newGrandTotalGst = fieldset.reduce((total, field) => {
      return total + (parseFloat(field.gst_amt || '0') || 0);
    }, 0);

    const newGrandTotalAmount = fieldset.reduce((total, field) => {
      return total + (parseFloat(field.total_amt || '0') || 0);
    }, 0);

    setGrandTotalGst(newGrandTotalGst);
    setGrandTotalAmount(newGrandTotalAmount);
  };

  const handleDateChanges = (date: any) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding leading zero if needed
    const day = ('0' + date.getDate()).slice(-2); // Adding leading zero if needed

    const formattedDate = `${year}-${month}-${day}`;

    setFormData(prevFormData => ({
      ...prevFormData,
      invoice_date: formattedDate
    }));
  };

  const handleDateChange = (date: any, index: number) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding leading zero if needed
    const day = ('0' + date.getDate()).slice(-2); // Adding leading zero if needed
    const formattedDate = `${year}-${month}-${day}`;
    const fieldset = [...fields];
    const updatedField = { ...fieldset[index] };
    updatedField['maintan_date'] = formattedDate;
    fieldset[index] = updatedField;
    setFields(fieldset);
  };

  useEffect(() => {
    fetchSupplier();
    fetchMaterial();
    fetchState();
  }, []);

  const fetchSupplier = async () => {
    const response = await getService('supplier');
    if (response.status === 200) {
      setSupplierList(response.data);
    }
  };
  const fetchMaterial = async () => {
    const response = await getService('material');
    if (response.status === 200) {
      setMaterialList(response.data);
    }
  };
  const fetchState = async () => {
    const response = await getService('gstlist');
    if (response.status === 200) {
      setStateList(response.data);
    }
  };

  const isObjectComplete = (obj: any) => {
    return Object.values(obj).every((value: any) => value.trim() !== '');
  };

  const handleSubmit = async () => {
    // console.log('fields', fields);
    // const filteredFields = fields.map(field => {
    //   const { unit, ...rest } = field;
    //   return rest;
    // });
    const filteredFields = fields.filter(isObjectComplete);
    if (filteredFields.length === 0) {
      toast.error('Please fill all the fields');
    } else {
      const combinedData = {
        ...formData,
        inventory: JSON.stringify(filteredFields)
      };
      const response = await addService('master_inventory', combinedData);
      if (response?.status === 200) {
        toast.success('SuccessFully Added', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        navigate('/inventoryViewList');
      } else if (response?.status === 500) {
        toast.error('Error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        validationErrorFromAPI(response.data);
      }
    }
  };

  return (
    <div>
      <h2 className="mb-4">Add Inventory</h2>
      <Row>
        <Col xs={12} xl={12}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={6} md={2}>
              <FloatingLabel controlId="floatingSelectTask" label="Supplier">
                <Form.Select
                  name="supplier_id"
                  value={formData.supplier_id}
                  onChange={handleInputChange}
                >
                  <option>Select Supplier</option>
                  {supplierList.length > 0 &&
                    supplierList.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.supplier_name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={2}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Enter Invoice number"
              >
                <Form.Control
                  name="invoice_no"
                  type="text"
                  placeholder="Invoice Number"
                  value={formData.invoice_no}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={2}>
              <DatePicker
                value={formData['invoice_date']}
                options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                onChange={([date]) => {
                  handleDateChanges(date);
                }}
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        placeholder="Project title"
                        ref={ref}
                        id="startDate"
                      />
                      <label htmlFor="startDate" className="ps-6">
                        Invoice Date
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>
            <Col sm={6} md={2} className="gy-3">
              <FloatingLabel controlId="floatingProjectOverview" label="Note">
                <Form.Control
                  name="note"
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: '70px' }}
                  value={formData.note}
                  onChange={handleInputChange}
                  // style={{ height: '100px' }}
                />
              </FloatingLabel>
            </Col>
            <Col sm={2} md={2}>
              <FloatingLabel controlId="floatingSelectTask" label="SBU">
                <Form.Select
                  name="sbu_id"
                  value={formData.sbu_id}
                  onChange={handleInputChange}
                >
                  <option>Select SBU</option>
                  {stateList.length > 0 &&
                    stateList.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {`${item.sbu_name}- ${item.gst_no}`}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={2} md={1}>
              <div className="d-flex align-items-center border rounded-2 pl-2 cursor-pointer">
                <div className="p-2 d-flex align-items-center">
                  <label
                    className="text-body fs-9 cursor-pointer"
                    htmlFor="attachments"
                  >
                    <FontAwesomeIcon icon={faPaperclip} className="me-1" />
                    <span className="ml-2">Upload Image</span>
                  </label>
                </div>
                <Form.Control
                  className="d-none"
                  type="file"
                  id="attachments"
                  onChange={handleImageChange}
                />
              </div>
              {image && (
                <div className="mt-3 border rounded position-relative">
                  <img
                    src={URL.createObjectURL(image)}
                    alt="Uploaded"
                    style={{ maxWidth: '100%' }}
                  />
                  <FontAwesomeIcon
                    onClick={() => setImage(null)}
                    icon={faRemove}
                    className="position-absolute top-0 end-0 m-1 bg-black px-1 rounded-2"
                  />
                </div>
              )}
            </Col>
            <div>
              <Col xs={12} xl={12}>
                {fields.map((field, index) => {
                  return (
                    <Row as="form" className="g-3 mb-6" key={index}>
                      <Col sm={4} md={2}>
                        <FloatingLabel
                          controlId="floatingSelectPrivacy"
                          label="Material"
                        >
                          <Form.Select
                            value={fields[index]['material_id']}
                            onChange={event => handleSelect(event, index)}
                          >
                            <option>Select Material</option>
                            {materialList.length > 0 &&
                              materialList.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Unit"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            disabled
                            name="unit"
                            value={fields[index]['unit']}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Barcode"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Project title"
                            name="barcode"
                            value={fields[index]['barcode']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Batch No"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Project title"
                            name="batch_no"
                            value={fields[index]['batch_no']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <DatePicker
                          value={fields[index]['maintan_date']}
                          options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                          onChange={([date]) => {
                            handleDateChange(date, index);
                          }}
                          render={(_, ref) => {
                            return (
                              <Form.Floating>
                                <Form.Control
                                  type="text"
                                  placeholder="Project title"
                                  ref={ref}
                                  id="startDate"
                                />
                                <label htmlFor="startDate" className="ps-6">
                                  EXP. date
                                </label>
                              </Form.Floating>
                            );
                          }}
                        />
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Quantity"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Project title"
                            name="stock"
                            value={fields[index]['stock']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Purchase Rate"
                        >
                          <Form.Control
                            type="number"
                            placeholder="number"
                            name="purchase_rate"
                            value={fields[index]['purchase_rate']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="GST %"
                        >
                          <Form.Control
                            type="text"
                            placeholder="GST"
                            name="gst"
                            value={fields[index]['gst']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="GST Amount"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            disabled
                            name="gst_amt"
                            value={fields[index]['gst_amt']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Total Amount"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            disabled
                            name="total_amt"
                            value={fields[index]['total_amt']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={2}>
                        <Button
                          variant="primary"
                          className="px-5"
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          onClick={() =>
                            index === 0
                              ? handleAddField()
                              : handleRemoveField(index, field.id)
                          }
                        >
                          {index === 0 ? 'Enter' : 'Close'}
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </div>
            <Row>
              <Col md={6}>
                <Card>
                  <Card.Header as="h5">Grand Totals</Card.Header>
                  <Card.Body>
                    <Row className="mb-2">
                      <Col>
                        <h6>Grand Total GST Amount:</h6>
                      </Col>
                      <Col className="text-end">
                        <h6>{grandTotalGst.toFixed(2)}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h6>Grand Total Amount:</h6>
                      </Col>
                      <Col className="text-end">
                        <h6>{grandTotalAmount.toFixed(2)}</h6>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={5} className="gy-6">
                <div className="d-flex justify-content-end gap-3">
                  <Link to="/inventoryViewList">
                    <Button variant="phoenix-primary" className="px-5">
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    variant="primary"
                    className="px-5 px-sm-15"
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                </div>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreateInventory;
