import { faEdit, faInr, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Col, Tab, Tabs } from 'react-bootstrap';
import SiteStageTab from './SiteStageTab';
import SiteDocumentListTable from './SiteDocumentListTable';
import InventoryListTable from './InventoryListTable';
import RequestForMaterialListTable from './RequestForMaterialListTable';
import AddSiteExpenses from './AddSiteExpenses';
import SiteExpenseListTable from './SiteExpenseListTable';
import SitBillingTab from './SitBillingTab';
import SitePerformanceTab from './SitePerformanceTab';
import { getService } from 'services/services';
import { Link, useParams } from 'react-router-dom';
import { Site } from 'data/project-management/projects';
import ChangeContractorModal from 'components/modals/ChangeContractorModal';
import AddSiteRetention from './AddSiteRetention';
import SitePerformance from './SitePerformance';
import { formatDate, validationErrorFromAPI } from 'helpers/utils';
import { Row } from 'react-bootstrap';
import CompleteSiteExpense from './CompleteSiteExpense';
import CompleteSiteDocument from './CompleteSiteDocument';

const initialSiteDetails: Site = {};

const CompletedSiteDetails = () => {
  const [siteDetails, setSiteDetails] = useState<Site>(initialSiteDetails);
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState<string>('Site Stage');
  const [showAlert, setShowAlert] = useState(false);
  const [role, setRole] = useState(0);
  const siteName = siteDetails?.site_name || '';

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const contractorFullName =
    (siteDetails?.contractor?.first_name ?? '') +
    ' ' +
    (siteDetails?.contractor?.last_name ?? '');

  const contractorId = siteDetails?.contractor?.id ?? '';

  const fetch = async () => {
    const response = await getService(`site_completed/${id}`);
    if (response.status === 200) {
      setSiteDetails(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  useEffect(() => {
    fetch();
  }, [showAlert]);

  const handleSelect = (key: string | null) => {
    if (key) {
      setActiveKey(key);
    }
  };

  const activeTabStyle = {
    backgroundColor: '#3874FF',
    color: 'white',
    fontSize: '20px'
  };
  const inactiveTabStyle = {
    // backgroundColor: 'white',
    // color: 'black',
    fontSize: '20px'
  };

  return (
    <div>
      <Row className="justify-content-between">
        <Col className="px-14">
          <h5>Completed Site Details</h5>
        </Col>
        <Col className="text-end px-14">
          {role === 1 && (
            <Link className="px-5" to={`/site/create-new/${id}`}>
              Edit Site
              <FontAwesomeIcon icon={faEdit} />
            </Link>
          )}
        </Col>
      </Row>
      <div className="mt-4">
        <div className="d-flex justify-content-around">
          <div>
            <h6>Company Name:</h6>
            <h6>
              Contractor:{' '}
              {siteDetails?.contractor
                ? `${siteDetails.contractor.first_name} ${siteDetails.contractor.last_name}`
                : ''}{' '}
              {''}{' '}
              {role === 1 && (
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => setShowAlert(true)}
                />
              )}
            </h6>
          </div>
          <div>
            <h6>
              WO ID/Name:{' '}
              {siteDetails?.workorder?.wo_id +
                '/' +
                siteDetails?.workorder?.name}
            </h6>
            <div>
              <h6>Start date: {formatDate(siteDetails?.start_date)}</h6>
              <h6>End date: {formatDate(siteDetails?.end_date)}</h6>
            </div>
          </div>
          <div>
            <h6>
              Site ID/Name:{' '}
              {siteDetails?.site_id + '/' + siteDetails?.site_name}
            </h6>
            {/* <h6>
              Budget: <FontAwesomeIcon icon={faInr} /> {budget}
            </h6> */}
          </div>
          <div>
            <h6>Site Address: {siteDetails?.address}</h6>
            {/* <h6>Progress: {progress}%</h6> */}
          </div>
        </div>
      </div>
      <Tabs
        defaultActiveKey="Site Stage"
        id="uncontrolled-tab-example"
        className="mb-3 d-flex mt-2"
        activeKey={activeKey}
        onSelect={handleSelect}
      >
        <Tab
          eventKey="Site Stage"
          title={
            <span
              className="border rounded px-4 fs-8"
              style={
                activeKey === 'Site Stage' ? activeTabStyle : inactiveTabStyle
              }
            >
              Site Stage
            </span>
          }
        >
          {activeKey === 'Site Stage' ? (
            <SiteStageTab
              siteId={id}
              contractorFullName={contractorFullName}
              contractorId={
                typeof contractorId === 'string'
                  ? parseInt(contractorId)
                  : contractorId
              }
            />
          ) : (
            ''
          )}
        </Tab>
        <Tab
          eventKey="Document"
          title={
            <span
              className="border rounded px-4 fs-8"
              style={
                activeKey === 'Document' ? activeTabStyle : inactiveTabStyle
              }
            >
              Document
            </span>
          }
        >
          {' '}
          {activeKey === 'Document' ? <CompleteSiteDocument siteId={id} /> : ''}
        </Tab>
        <Tab
          eventKey="Inventory"
          title={
            <span
              className="border rounded px-4 fs-8"
              style={
                activeKey === 'Inventory' ? activeTabStyle : inactiveTabStyle
              }
            >
              Inventory
            </span>
          }
        >
          {activeKey === 'Inventory' ? <InventoryListTable siteId={id} /> : ''}
        </Tab>
        <Tab
          eventKey="Site Expenses"
          title={
            <span
              className="border rounded px-4 fs-8"
              style={
                activeKey === 'Site Expenses'
                  ? activeTabStyle
                  : inactiveTabStyle
              }
            >
              Site Expenses
            </span>
          }
        >
          {activeKey === 'Site Expenses' ? (
            <div className="mt-5">
              {/* <AddSiteExpenses /> */}
              <CompleteSiteExpense siteId={id} />
            </div>
          ) : (
            ''
          )}
        </Tab>
        {(role === 1 || role === 2 || role === 3) && (
          <Tab
            eventKey="Billing"
            title={
              <span
                className="border rounded px-4 fs-8"
                style={
                  activeKey === 'Billing' ? activeTabStyle : inactiveTabStyle
                }
              >
                Billing
              </span>
            }
          >
            {activeKey === 'Billing' ? (
              <SitBillingTab siteId={id} siteName={siteName} />
            ) : (
              ''
            )}
          </Tab>
        )}
        <Tab
          eventKey="Site Performance"
          title={
            <span
              className="border rounded px-4 fs-8"
              style={
                activeKey === 'Site Performance'
                  ? activeTabStyle
                  : inactiveTabStyle
              }
            >
              Site Performance
            </span>
          }
        >
          {activeKey === 'Site Performance' ? (
            <SitePerformance
              siteName={siteDetails?.site_name}
              budget={siteDetails?.budget}
              siteId={id}
              startDate={siteDetails?.start_date}
              endDate={siteDetails?.end_date}
              contractorName={`${siteDetails?.contractor?.first_name} ${siteDetails?.contractor?.last_name}`}
              description={siteDetails?.description}
            />
          ) : (
            ''
          )}
        </Tab>
        {(role === 1 || role === 2 || role === 3) && (
          <Tab
            eventKey="Retention"
            title={
              <span
                className="border rounded px-4 fs-8"
                style={
                  activeKey === 'Retention' ? activeTabStyle : inactiveTabStyle
                }
              >
                Retention
              </span>
            }
          >
            {activeKey === 'Retention' ? (
              <AddSiteRetention budget={siteDetails.budget} />
            ) : (
              ''
            )}
          </Tab>
        )}
        <Tab
          eventKey="Site Survey"
          title={
            <span
              className="border rounded px-4 fs-8"
              style={
                activeKey === 'Site Survey' ? activeTabStyle : inactiveTabStyle
              }
            >
              Site Survey
            </span>
          }
        >
          {/* {activeKey === 'Site Survey' ? <AddSiteRetention /> : ''} */}
          {activeKey === 'Site Survey' ? '' : ''}
        </Tab>
      </Tabs>
      {showAlert && (
        <ChangeContractorModal
          name={'Select New Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          // handleAction={() => console.log('rrr')}
          siteId={siteDetails.id}
        />
      )}
    </div>
  );
};

export default CompletedSiteDetails;
