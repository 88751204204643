import { faPaperclip, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import {
  imageURL,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, getService } from 'services/services';

interface Unit {
  id: number;
  unit_title: string;
}

const CreateMaterial = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [materialName, setMaterialName] = useState('');
  const [unitName, setUnitName] = useState('');
  const [unitList, SetUnitList] = useState<Unit[]>([]);
  const [image, setImage] = useState<any>(null);
  const [imagePre, setImagePre] = useState<any>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImage(file);
      setImagePre('');
    } else {
      setImage(null);
    }
  };

  useEffect(() => {
    if (id) {
      fetchMaterial(id);
    }
  }, [id]);

  const fetchMaterial = async (id: any) => {
    const response = await getService(`material/${id}`);
    if (response.status === 200) {
      setMaterialName(response.data.name);
      setUnitName(response.data.unit);
      setImagePre(response.data.image);
    }
  };

  const handleClose = () => {
    setMaterialName('');
    setUnitName('');
    setImage('');
  };

  const handleSave = async () => {
    if (materialName === '') {
      return;
    } else {
      const data = jsonToFormData({
        name: materialName,
        unit: unitName,
        image: image
      });

      let response: any = {};
      if (id) {
        response = await addService(`material/${id}`, data);
      } else {
        response = await addService(`material`, data);
      }
      if (response?.status === 200) {
        toast.success('SuccessFully Added', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        navigate('/material');
      } else if (response?.status === 500) {
        toast.error('Error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        validationErrorFromAPI(response.data);
      }
    }
  };

  useEffect(() => {
    fetchUnit();
  }, []);

  const fetchUnit = async () => {
    const response = await getService('unit');
    if (response.status === 200) {
      SetUnitList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  return (
    <div>
      <h2 className="mb-4"> {id ? 'Edit' : 'Create'} Material</h2>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Material Name"
              >
                <Form.Control
                  type="text"
                  placeholder="Material Name"
                  value={materialName}
                  onChange={e => setMaterialName(e.target.value)}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectPrivacy" label="Unit">
                <Form.Select
                  value={unitName}
                  onChange={e => setUnitName(e.target.value)}
                >
                  <option>Select Unit</option>
                  {unitList.length > 0 &&
                    unitList.map((unit: Unit) => (
                      <option key={unit.id} value={unit.id}>
                        {unit.unit_title}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={4} md={2}>
              <div className="d-flex align-items-center border rounded-2 pl-2 cursor-pointer">
                <Button className="p-2 d-flex align-items-center">
                  <label
                    className="text-body fs-9 cursor-pointer"
                    htmlFor="attachments"
                  >
                    <FontAwesomeIcon icon={faPaperclip} className="me-1" />
                    <span className="ml-2">Upload Image</span>
                  </label>
                </Button>
                <Form.Control
                  className="d-none"
                  type="file"
                  id="attachments"
                  onChange={handleImageChange}
                />
              </div>
              {/* {image && (
                <div className="mt-3 border rounded position-relative">
                  <img
                    src={
                      id
                        ? `${imageURL}/public/${image}`
                        : URL.createObjectURL(image)
                    }
                    alt="Uploaded"
                    style={{ maxWidth: '100%' }}
                  />
                  <FontAwesomeIcon
                    onClick={() => setImage(null)}
                    icon={faRemove}
                    className="position-absolute top-0 end-0 m-1 bg-black px-1 rounded-2"
                  />
                </div>
              )} */}
              {id && imagePre ? (
                <div className="mt-3 border rounded position-relative">
                  <img
                    src={`${imageURL}/public/${imagePre}`}
                    alt="Profile Image"
                    style={{ maxWidth: '100%', borderRadius: '50%' }}
                  />
                  <FontAwesomeIcon
                    onClick={() => [setImage(''), setImagePre('')]}
                    icon={faRemove}
                    className="position-absolute top-0 end-0 m-1 bg-black px-1 rounded-2"
                  />
                </div>
              ) : (
                image && (
                  <div className="mt-3 border rounded position-relative">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Profile Image"
                      style={{ maxWidth: '100%', borderRadius: '50%' }}
                    />
                    <FontAwesomeIcon
                      onClick={() => [setImage(''), setImagePre('')]}
                      icon={faRemove}
                      className="position-absolute top-0 end-0 m-1 bg-black px-1 rounded-2"
                    />
                  </div>
                )
              )}
            </Col>

            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to="/material">
                  <Button
                    variant="phoenix-primary"
                    className="px-5"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSave}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreateMaterial;
