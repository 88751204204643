import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import ReactSelect from 'components/base/ReactSelect';
import { useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getService } from 'services/services';

interface Common {
  id: number;
  name: string;
}

interface WorkOrder {
  id: number;
  name: string;
}
interface Contractor {
  id: number;
  first_name: string;
  last_name: string;
}

interface Stage {
  id: number;
  stage_name: string;
}

interface Field {
  wo_id: string;
  site_name: string;
  site_id: string;
  country: string;
  state: string;
  city: string;
  address: string;
  contractor_id: string;
  start_date: string | Date;
  end_date: string | Date;
  stage: string;
}

const CreateSiteRetention = () => {
  const [country, setCountry] = useState<Common[]>([]);
  const [state, setState] = useState<Common[]>([]);
  const [city, setCity] = useState<Common[]>([]);
  const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>([]);
  const [contractorList, setContractorList] = useState<Contractor[]>([]);
  const [stageList, setStageList] = useState<Stage[]>([]);

  const [fields, setFields] = useState<Field>({
    wo_id: '',
    site_name: '',
    site_id: '',
    country: '',
    state: '',
    city: '',
    address: '',
    contractor_id: '',
    start_date: '',
    end_date: '',
    stage: ''
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('========', event.target);
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));

    if (name === 'country') {
      getState(value);
    }

    if (name === 'state') {
      getCity(value);
    }
  };

  useEffect(() => {
    getCountry();
    getWorkOrder();
    getContractor();
    getStage();
  }, []);

  const getStage = async () => {
    const response = await getService('stage');
    if (response.status === 200) {
      setStageList(response.data);
    }
  };
  const getWorkOrder = async () => {
    const response = await getService('workorder');
    if (response.status === 200) {
      setWorkOrderList(response.data);
    }
  };
  const getContractor = async () => {
    const response = await getService('contractor');
    if (response.status === 200) {
      setContractorList(response.data);
    }
  };
  const getCountry = async () => {
    const response = await getService('country');
    if (response.status === 200) {
      setCountry(response.data);
    }
  };
  const getState = async (country_id: string) => {
    const response = await getService(`state-by-country/${country_id}`);
    if (response.status === 200) {
      setState(response.data);
    }
  };
  const getCity = async (state_id: string) => {
    const response = await getService(`city-by-state/${state_id}`);
    if (response.status === 200) {
      setCity(response.data);
    }
  };
  return (
    <div>
      <h2 className="mb-4">Create Site Retention</h2>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={6} md={8}>
              <FloatingLabel controlId="floatingInputGrid" label="Site Name">
                <Form.Control
                  type="text"
                  placeholder="Site Name"
                  name="site_name"
                  value={fields['site_name']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="WO ID">
                <Form.Control
                  type="text"
                  placeholder="WO ID"
                  name="site_id"
                  value={fields['site_id']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <DatePicker
                value={fields['start_date']}
                options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                onChange={([date]) => {
                  setFields({
                    ...fields,
                    start_date: date
                  });
                }}
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        placeholder="Project title"
                        ref={ref}
                        id="startDate"
                      />
                      <label htmlFor="startDate" className="ps-6">
                        Start date
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>
            <Col sm={6} md={4}>
              <DatePicker
                value={fields['end_date']}
                options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                onChange={([date]) => {
                  setFields({
                    ...fields,
                    end_date: date
                  });
                }}
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        type="date"
                        placeholder="Deadline"
                        ref={ref}
                        id="deadline"
                      />
                      <label htmlFor="deadline" className="ps-6">
                        Estimated End Date
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectTeam" label="Retention">
                <Form.Select
                  name="contractor_id"
                  value={fields['contractor_id']}
                  onChange={handleSelect}
                >
                  <option>Retention Status</option>
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to="/siteRetentionListView">
                  <Button variant="phoenix-primary" className="px-5">
                    Cancel
                  </Button>
                </Link>
                <Button variant="primary" className="px-5 px-sm-15">
                  Create Site Retention
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreateSiteRetention;
