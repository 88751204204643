const AccountList = () => {
  return (
    <div>
      <h1>Account List</h1>
      <h4>Supplire Invoice</h4>
      <h4>Vouches/Expenses</h4>
      <h4>GST Report</h4>
    </div>
  );
};
export default AccountList;
