import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import AllMaterialList from './AllMaterialList';
import { addService, deleteService, getService } from 'services/services';
import { jsonToFormData, validationErrorFromAPI } from 'helpers/utils';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { toast } from 'react-toastify';
import FinalMaterialList from './FinalMaterialList';
import Avatar from 'components/base/Avatar';
import { baseLocalURL } from 'config';
interface Props {
  siteId: any;
}
interface Material {
  id?: number;
  name?: string;
  estimated_qty?: string;
  unit?: string;
  image?: any;
  unitdata?: {
    unit_title: string;
  };
}
interface FinalMaterial {
  id: number;
  estimated_qty?: string;
  unitdata?: {
    unit_title: string;
  };
  quantity?: string;
  material?: {
    name: string;
  };
}

const RequestForMaterialListTable: React.FC<Props> = ({ siteId }) => {
  const [materialList, setMaterialList] = useState<Material[]>([]);
  const [finalMaterialList, setFinalMaterialList] = useState<FinalMaterial[]>(
    []
  );

  console.log('finalMaterialList', finalMaterialList);

  useEffect(() => {
    industryMaterialFun();
    fetchFinalMaterialList();
  }, []);

  useEffect(() => {
    fetchFinalMaterialList();
  }, []);

  const fetchFinalMaterialList = async () => {
    const response = await getService(
      `estimatedSiteInventories_by_site_id/${siteId}`
    );
    if (response.status === 200) {
      setFinalMaterialList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const industryMaterialFun = async () => {
    const response = await getService(
      `site_inventory_material_list_by_site_id/${siteId}`
    );
    if (response.status === 200) {
      setMaterialList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const addInventoryHandler = async () => {
    const formattedData = finalMaterialList.map((item: any) => ({
      site_id: item.site.id.toString(),
      material_id: item.material.id.toString(),
      unit: item.unitdata.id.toString(),
      quantity: item.estimated_qty.toString(),
      // rate_per_site: '0',
      // site_rate: '0',
      // gst: '0',
      // tpgst: '0',
      estimat_id: item.id.toString()
    }));
    const data = new FormData();
    data.append('inventory', JSON.stringify(formattedData));
    const response = await addService('estimate_site_inventory', data);
    if (response.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetchFinalMaterialList();
      console.log(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const membersTablecolumns: ColumnDef<Material>[] = [
    {
      accessorKey: 'name',
      header: 'Material Name',
      cell: ({ row: { original } }) => {
        const { name } = original;
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{name}</p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'image',
      header: 'Image',
      cell: ({ row: { original } }) => {
        const { image } = original;
        return <Avatar src={`${baseLocalURL}/public/${image}`} size="m" />;
      },
      meta: {
        headerProps: { style: { width: '20%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'unitdata.unit_title',
      header: 'Unit',
      meta: {
        headerProps: { style: { width: '20%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      cell: ({ row: { original } }) => {
        const { id, unit } = original;
        return (
          <Col sm={12} md={12} key={`quantities_${id}`}>
            <FloatingLabel
              controlId={`floatingInputGrid_${id}`}
              label="Quantity"
            >
              <Form.Control
                placeholder="Quantity"
                onBlur={e => handleSave(e.target.value, id, unit)}
              />
            </FloatingLabel>
          </Col>
        );
      },
      meta: {
        headerProps: { style: { width: '20%' } },
        cellProps: { className: 'text-body' }
      }
    }
  ];

  const finalMaterialTablecolumns: ColumnDef<FinalMaterial>[] = [
    {
      accessorKey: 'material.name',
      header: 'Material Name',
      cell: ({ row: { original } }) => {
        const { material } = original;
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {material?.name}
            </p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'unitdata.unit_title',
      header: 'Unit',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'estimated_qty',
      header: 'Quantity',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'quantity',
      header: 'Cancel',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <>
            <Button variant="danger" onClick={() => handleCancel(id)}>
              Cancel
            </Button>
          </>
        );
      },
      meta: {
        headerProps: { style: { width: '20%' } },
        cellProps: { className: 'text-body' }
      }
    }
  ];

  const handleSave = async (quantity: any, id: any, unit: any) => {
    // Handle save logic...
    const data = jsonToFormData({
      material_id: id,
      estimated_qty: quantity,
      site_id: siteId,
      unit: unit
    });

    const response = await addService('estimatedSiteInventories', data);
    if (response.status === 200) {
      fetchFinalMaterialList();
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    } else {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    }
  };

  const handleCancel: (id: any) => Promise<void> = async id => {
    const response = await deleteService(`estimatedSiteInventories/${id}`);
    if (response.status === 200) {
      // setShowDeleteSuccess(true);
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetchFinalMaterialList();
    }
  };

  return (
    <div className="d-flex">
      <div className="mx-4 px-4 py-3 border rounded">
        <AllMaterialList
          materialList={materialList || []}
          membersTablecolumns={membersTablecolumns}
        />
      </div>
      <div className="mx-4 px-4 py-3 border rounded">
        <FinalMaterialList
          finalMaterialData={finalMaterialList || []}
          finalMaterialTablecolumns={finalMaterialTablecolumns}
          addInventory={addInventoryHandler}
        />
      </div>
    </div>
  );
};

export default RequestForMaterialListTable;
