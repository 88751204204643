/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type AddPaymentAction = () => void;
type PaymentDetailsAction = () => void;
type InvoiceDetailsAction = () => void;

interface ActionDropdownItemsProps {
  addPaymentAction?: AddPaymentAction; // Make the prop optional
  paymentDetailsAction?: PaymentDetailsAction;
  invoiceDetailsAction?: InvoiceDetailsAction;
}

const InventoryDropdownItems: React.FC<ActionDropdownItemsProps> = ({
  addPaymentAction = () => {},
  paymentDetailsAction = () => {},
  invoiceDetailsAction = () => {}
}) => {
  return (
    <>
      <Dropdown.Item
        eventKey="1"
        className="text-info"
        onClick={addPaymentAction}
      >
        Add Payment
      </Dropdown.Item>
      {/* <Dropdown.Item eventKey="2">Export</Dropdown.Item> */}
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-info"
        onClick={paymentDetailsAction}
      >
        Payment Details
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-info"
        onClick={invoiceDetailsAction}
      >
        View Invoice Details
      </Dropdown.Item>
    </>
  );
};

export default InventoryDropdownItems;
